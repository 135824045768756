import React, {useState, useEffect} from 'react';


//material
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useNavigate} from 'react-router-dom'
import { rgbToHex } from '@mui/material';
import partnerAPI from '../../../Services/partnerAPI';

export default function WalletList(){
const navigate = useNavigate();
const [loadingWallet, setLoadingWallet] =useState(false)

useEffect(() => {
    WalletPessoaList();
}, []);

const [listCrypto, setlistCrypto] = useState({
    cdPessoa: 0,
    walletCryptosPessoa:[]
});
//
const WalletPessoaList = async () => {
    
    console.log(JSON.stringify(sessionStorage.getItem("token")), 'sessionStorage.getItem WalletPessoaList');
    
    const config = {
        headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
        },
    };
    
    await partnerAPI.post("/Partner/WalletPerson",{
        cryptoUserID: window.sessionStorage.getItem("uid")
    },config).then(
        result => {
            setlistCrypto(result.data);
            setLoadingWallet(true);
            //console.log(result, 'walletPessoa WalletPessoaList');
        }
    ).catch(error=>{
        console.log(error.response, 'walletPessoa WalletPessoaList')
    });

}

    return(
        <div>

            {
                listCrypto.walletCryptosPessoa.length === 0 &&
                <Typography>
                    <Skeleton style={{padding: 10}} />
                    <Skeleton style={{padding: 10}} />
                    <Skeleton style={{padding: 10}} />
                </Typography>
            }  
            
            { loadingWallet &&

                <>
                
                    {
                        listCrypto.walletCryptosPessoa.map((crypto, index) =>
                        <Card 
                            key={index} 
                            style={{height: 63, margin: 5, boxShadow: '1px 2px 9px #8A8A8A'}} 
                            onClick={()=> navigate('/saque', {
                                state: {
                                    cdCrypto: crypto.cdCrypto,
                                    coins: crypto.vlSaldo,
                                    eliasCrypto: crypto.eliasCrypto
                                }
                        })}>

                            <CardActionArea onClick={()=> navigate('/saque', {
                                state: {
                                    cdCrypto: crypto.cdCrypto,
                                    coins: crypto.vlSaldo,
                                    eliasCrypto: crypto.eliasCrypto
                                }
                            })}>
                                <div key={index}>
                                    <div className='row' >
                                        <div className='col-sm-1' >
                                            <Avatar alt="Remy Sharp" style={{margin: 15, width: 30, height: 30}} src={crypto.urlImgCrypto} />
                                        </div>
                                        <div className='col-sm-10' style={{marginLeft:20, marginTop: 13,paddingLeft: 17,}}>
                                            <a style={{marginTop: 5}}><b>{Intl.NumberFormat('pt-BR').format(crypto.vlSaldo)}</b></a>
                                            <p>{crypto.eliasCrypto}</p>
                                        </div>
                                    </div>
                                </div>
                            </CardActionArea>
                        </Card>

                        )
                    }
                </>
               
            }
                


        </div> 
      ) 
}