import React, {useContext, useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';

//material
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import {useNavigate, useSearchParams, useLocation} from 'react-router-dom';

import authContext from '../../../Context/authContext';
import accessLoginWhiteLabelContext from '../../../Context/accessLoginWhiteLabelContext';
import navContext from '../../../Context/navContext';

import authAPI from '../../../Services/authAPI';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import partnerAPI from '../../../Services/partnerAPI';

export default function CadastroConta(){
    useEffect(() => {
        ajustarScreen();
    }, []);

    const navigate = useNavigate();
    const location  = useLocation();

    const [emailTxt, setEmailTxt] = useState('');
    const [confirmarEmailTxt, setConfirmarEmailTxt] = useState('');
    const [senhaTxt, setSenhaTxt] = useState('');
    const [confirmaSenhaTxt, setConfirmaSenhaTxt] = useState('');

    const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(0);
    const [dynamicPaddingTop, setDynamicPaddingTop] = useState(0);
    const [visible, setVisible] = useState(false)

    const  {authenticated, setAuthenticated} = useContext(authContext);
    const {accessLoginWhiteLabel, setAccessLoginWhiteLabel} = useContext(accessLoginWhiteLabelContext);
    const  {authenticatedNav, setAuthenticatedNav} = useContext(navContext);

    const [searchParamsQueryString] = useSearchParams();
    const [queryStringToken] = useState(searchParamsQueryString.get('token'));

    const ajustarScreen = () =>{
        //console.log(window.screen.width, window.screen.height);
        if(window.screen.width === 1366 && window.screen.height === 768){
            //calcularScreen(window.screen.width, window.screen.height)
            setDynamicPaddingBottom(120);
            setDynamicPaddingTop(1);
            return;
        }else{
            calcularScreen(window.screen.width, window.screen.height)
        }
    }

    const calcularScreen = (width,height) =>{
        let ttBottom = width * 100 / window.screen.width + 248
        let ttTop = height * 50 / window.screen.height + 20
        // console.log(ttBottom, 'calcularScreen Bottom');
        // console.log(ttTop, 'calcularScreen Top');

        setDynamicPaddingBottom(ttBottom);
        setDynamicPaddingTop(ttTop);
    }

    const loginAposCadastro = async (body)  =>{
        debugger;
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };

        //debugger;
        //'/api/Authentication/login'
        partnerAPI.post("/Login/Authentication",{
          email: body.email,
          password: body.password,
          token: body.token
        },config).then(
          result => {
            debugger;
            window.sessionStorage.setItem("token", result.data.content.token);
            window.sessionStorage.setItem("uid", result.data.content.cryptoUserID);
            window.sessionStorage.setItem("contract", body.token);
            setAuthenticated(true);
            setAuthenticatedNav(true);
            setAccessLoginWhiteLabel(0);
            navigate('/home');
            setVisible(false);
          }
        ).catch(error =>{

        });
    
    }

    const cadastrarConta = () =>{
        //debugger;
        console.log(emailTxt.length, confirmarEmailTxt, ' emailtxt cadastrarConta');
        console.log(senhaTxt.length, confirmaSenhaTxt, ' senhatxt cadastrarConta');

        if(queryStringToken === null){
            toast.error('Algo deu errado contate o administrador do portal', {
                position: 'top-right'
            });

            return;
        }

        if(emailTxt.length === 0 || confirmarEmailTxt.length === 0){
            toast.warning('Insira todos os Campos', {
                position: 'top-right'
            });
            return;
        }
        
        if(senhaTxt.length === 0 || senhaTxt.length === 0){
            toast.warning('Insira todos os Campos', {
                position: 'top-right'
            });
            return;
        }

        const validacaoCadastro ={
            emailValidado: false,
            senhaValidado: false
        }
    
        if(emailTxt === confirmarEmailTxt){
            validacaoCadastro.emailValidado = true;
        }else{
            toast.warning('E-mails não conferem', {
                position: 'top-right'
            });
            return;
        }
    
        if(senhaTxt === confirmaSenhaTxt){
            validacaoCadastro.senhaValidado = true;
        }else{
            toast.warning('Senhas não conferem', {
                position: 'top-right'
            });
            return;
        }

       setVisible(true);

       const body ={
        email: emailTxt.trim(),
        password: senhaTxt.trim(),
        token: queryStringToken
       }

       if(validacaoCadastro.emailValidado && validacaoCadastro.senhaValidado){
        // faz cadastroopi

        partnerAPI.post('/Login/Register',body).then( result => {

            console.log(result.data.retorno, 'cadastrarConta')
            
            if(result.data.retorno === "Existe E-mail Cadastrado"){
              // console.log('Existe E-mail Cadastrado');
              toast.warning('Existe E-mail Cadastrado', {
                position: 'top-right'
              });

              setVisible(false);

              setEmailTxt('')
              setConfirmarEmailTxt('')
              setSenhaTxt('')
              setConfirmaSenhaTxt('')
              return;
            }

            if(result.data.retorno === "Usuario existente na base"){
                // console.log('Existe E-mail Cadastrado');
                toast.warning('Usuario existente na base', {
                    position: 'top-right'
                });
  
                setVisible(false);
  
                setEmailTxt('')
                setConfirmarEmailTxt('')
                setSenhaTxt('')
                setConfirmaSenhaTxt('')
                return;
              }

            if(result.data.retorno === "E-mail Invalido"){
                // console.log('Existe E-mail Cadastrado');
                toast.warning('E-mail Invalido', {
                    position: 'top-right'
                });
                setVisible(false);
                
                setEmailTxt('')
                setConfirmarEmailTxt('')
                setSenhaTxt('')
                setConfirmaSenhaTxt('')
                return;
            }

            if(result.data.retorno === "Preencha todos os Campos"){
                // console.log('Existe E-mail Cadastrado');
                toast.warning('Preencha todos os Campos', {
                    position: 'top-right'
                });
                
                setVisible(false);
                return;
            }

            if(result.data.retorno === "Conta Criada com Sucesso"){
              // console.log('Conta Criada com Sucesso');
              toast.success('Conta criada com Sucesso', {
                position: 'top-right'
              });

              loginAposCadastro(body);
            }

        }).catch(error => {
            //console.log(error,'/partnerAPI/cadastroLogin/')
            setVisible(false);     
            if (error.response.status === 401) {

            }
        });
    }

}
    
    return (
        <div className="container" style={{ paddingBottom: dynamicPaddingBottom, paddingTop: dynamicPaddingTop,}}>
            <div className='col-sm-5 text-center mx-auto'>
                <Card className="col-sm-12" sx={{ marginTop: 15, marginBottom: 20, borderRadius: 5,}}>
                    <CardContent >
                        <div className='col-sm-12 mx-auto' style={{marginTop: 35}}>
                            <h2>Bem vindo ao ciPay</h2>
                            <p>Crie sua conta sem Burocracia</p>
                        </div>

                        {
                            !visible &&
                            
                            <div >
                                <div>
                                    <TextField className="col-sm-12"  label="E-mail" value={emailTxt} variant="standard" onChange={(e)=> setEmailTxt(e.target.value)}/>
                                    <TextField className="col-sm-12" label="Confirmar E-mail"  value={confirmarEmailTxt}  variant="standard" onChange={(e)=> setConfirmarEmailTxt(e.target.value)}/>
                                    <TextField className="col-sm-12" label="Senha" type="password" value={senhaTxt}  variant="standard" onChange={(e)=> setSenhaTxt(e.target.value)}/>
                                    <TextField className="col-sm-12" label="Confirmar Senha" type="password" value={confirmaSenhaTxt}  variant="standard" onChange={(e)=> setConfirmaSenhaTxt(e.target.value)}/>
                                </div>
                                
                                <div className="my-3">
                                    <Button className="col-sm-12" variant="outlined" style={{backgroundColor: '#C08E39', color: 'black', borderBlockColor: 'aliceblue'}} onClick={()=> cadastrarConta()}>Criar Conta</Button>
                                </div>
                                
                            </div>

                        }
                        
                        {
                            visible &&
                            <div style={{paddingLeft: 215, margin: 20}}>
                                <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                                    <CircularProgress color="secondary" />
                                </Stack>
                            </div>
                        }
   
                    </CardContent>
                </Card>
            </div>
            <ToastContainer />
        </div>
    )
        
}