import axios from 'axios';

const pixApi = axios.create({
    baseURL: 'https://apigatewaycipay.azure-api.net/corporate/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials':true
    },
})

export default pixApi; 