import React from 'react'
import '../Footer/footer.styles.css'

export default function Footer(props){

    return (          
        <footer className="footer-clean">                
            <div className="row">                                                
                <div>                        
                    <img src="/Assets/img/ci_3d_2_180px.png" style={{width: 40}} />
                </div>                    
            </div>
        </footer>  
        
    );
}