import React from "react"

//material

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
 



export default function SaibaMaisStacking (){
    return(
        <>
        <Box className="col-sm-9 mb-5  pb-5 ">
            <CardContent className="">
                <Card className="p-5 ">
                   <div>
                    Teste
                   </div>
                </Card>
            </CardContent>
        </Box>       
        </>
    )
}