import React, {useState, useEffect}  from 'react';
import { DataGrid } from '@mui/x-data-grid';
 

export default function Tabela({data,columns}){
   
    useEffect(()=>{
       
       //console.log(data, "dentro do componete TABELA");
        //console.log(columns, "dentro do componete COLUNA")
        
    },[data, columns])

    return(
        <>          
            <div style={{ height: 400, width: '100%',backgroundColor:'white' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    className='ps-2' 
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    style={{cursor:"pointer",   }} 
                    checkboxSelection
                />
            </div>
        </>
    )
}