import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Button,
  Paper,
  TextField,
  Alert,
  Avatar
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import HeightIcon from '@mui/icons-material/Height';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState, useContext, useRef } from "react";
import authContext from "../../../Context/authContext";
import partnerAPI  from "../../../Services/partnerAPI";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { Margin } from "@mui/icons-material";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Swap() {
  const { authenticated, setAuthenticated } = useContext(authContext);

  const [habilitadoBtn, setHabilitadoBtn] = useState(true);
  const selectRef = useRef(null);
  const buttonRef = useRef(null);
  const [texEndereco, setTexEndereco ]= useState('');
  const [listCrypto, setlistCrypto] = useState({
    cdPessoa: 0,
    walletCryptosPessoa: [],
  });

  const [coinDe, setCoinDe] = useState("");
  const [openCoinDe, setOpenCoinDe] = useState(false);
  const [balanceDe, setBalanceDe] = useState('');

  const [colorDisableBtn, setColorDisableBtn] = useState('#D4AA51');

  const [coinPara, setCoinPara] = useState("");
  const [openCoinPara, setOpenCoinPara] = useState(false);
  const [balancePara, setBalancePara] = useState(false);
 
  const [saqueSelect, setSaqueSelect] = useState(false);

  const [amountDe, setAmountDe] = useState("0.0");
  const [amountPara, setAmountPara] = useState("0.0");

  const [loadingMsg, setLoadingMsg] = useState(false)
  const [loadingDe, setLoadingMsgDe] = useState(false)
  const [loadingPara, setLoadingMsgPara] = useState(false)

  const modelSwap = {
    fromCoinID: coinDe,
    fromAmount: '',
    toCoinID: coinPara,
    toAmount: ''
  };

  useEffect(() => {
    loadCryptos();
    buscarMeuEndereco();
    configuracoesIniciais();
  }, []);



  const configuracoesIniciais = () => {
    //debugger;
   
    handleChangeCoinDe(2);
    handleChangeCoinPara(3);

  };

  const loadCryptos = async () => {
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    await partnerAPI 
      .post(
        "/Partner/WalletPerson",
        {
          cryptoUserID: sessionStorage.getItem("uid"),
        },
        config
      )
      .then((result) => {
        setlistCrypto(result.data);
        setSaqueSelect(true);
        console.log(result, "loadCryptos");
      })
      .catch((error) => {
        console.log(error.response, "loadCryptos");
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
      });
  };

  const handleCloseCoinDe = () => {
    setOpenCoinDe(false);
  };

  const handleOpenCoinDe = () => {
    setOpenCoinDe(true);
  };

  const handleChangeCoinDe = async (event) => {

    if (selectRef.current) {
      selectRef.current.blur();
    }
    
    if(event === 2){
      //alert('event diferente')
      setCoinDe(2);
      const result = await localizarSaldoCryptoDe(2);
      return;
    }
    
    setCoinDe(event.target.value);
    const result = await localizarSaldoCryptoDe(event.target.value);

    if(amountDe !== "0.0" ){
      setAmountDe("0.0");
      setAmountPara("0.0");
    }

  };

  const handleCloseCoinPara = () => {
    setOpenCoinPara(false);
  };

  const handleOpenCoinPara = () => {
    setOpenCoinPara(true);
  };
  
  const handleChangeCoinPara = async (event) => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
    
    if(event === 3){
      //alert('event diferente 2')
      setCoinPara(3);
      const result = await localizarSaldoCryptoPara(3);
      return;
    }

    setCoinPara(event.target.value);
    const result = await localizarSaldoCryptoPara(event.target.value);

    if(amountDe !== "0.0"){
      console.log("entrou handleChangeCoinPara")
      modelSwap.toCoinID = coinPara;
      modelSwap.fromAmount = amountDe;
      console.log(modelSwap,'modelSwap ConverteDeParaMercadoCriptomoeda Para')
      ConverteDeParaMercadoCriptomoeda(modelSwap);
  
      setCoinPara(event.target.value);
      
    }
  };

  const buscarMeuEndereco = async () =>{
    const config = {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
        },
  
    };

    partnerAPI .post('/Partner/HashAddress', {
        cryptoUserID: sessionStorage.getItem("uid")
    },config).then(
        result => {
          setTexEndereco(result.data.hashWallet);
          console.log(result.data, 'buscarMeuEndereco');
        }
    ).catch(error =>{
        // console.log(error.response, 'buscarMeuEndereco');
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
    })
  }

  const localizarSaldoCryptoDe = async(cdCryptoValue) => {
    const config = {
      headers: { 
        Authorization: "Bearer " + sessionStorage.getItem("token")
      },

    };

    partnerAPI .post('/Partner/BalanceCoinWallet', {
      cryptoUserID: sessionStorage.getItem("uid"),
      cdCrypto: cdCryptoValue.toString()
    },config).then(result => {
      console.log(result, 'localizarSaldoCryptoDe /Partner/BalanceCoinWallet')
      if(result.status === 204){
        let saldo = listCrypto.walletCryptosPessoa.find(x=> x.cdCrypto === cdCryptoValue).vlSaldo;
        setBalanceDe(saldo);
        return;
      }

      setBalanceDe(result.data.vlSaldo);
      
    console.log(result, 'localizarSaldoCrypto DE')
    }
    ).catch(error => {
      console.log(error.response, 'localizarSaldoCrypto')
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });

  }

  const localizarSaldoCryptoPara = async(cdCryptoValue) => {
    const config = {
      headers: { 
        Authorization: "Bearer " + sessionStorage.getItem("token")
      },

    };

    partnerAPI .post('/Partner/BalanceCoinWallet', {
      cryptoUserID: sessionStorage.getItem("uid"),
      cdCrypto: cdCryptoValue.toString()
    },config).then(result => {

      if(result.status === 204){
        let saldo = listCrypto.walletCryptosPessoa.find(x=> x.cdCrypto === cdCryptoValue).vlSaldo;
        setBalancePara(saldo);
        return;
      }

      setBalancePara(result.data.vlSaldo);
      
      console.log(result, 'BalanceCoinWallet PARA')
    }
    ).catch(error => {
      console.log(error.response, 'BalanceCoinWallet PARA')
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });

  }

  const ConverteDeParaMercadoCriptomoeda = async(modelSwap) => {
    console.log(modelSwap, 'tentando swap')
    setLoadingMsgPara(true);

    const config = {
      headers: { 
        Authorization: "Bearer " + sessionStorage.getItem("token")
      },

    };

    partnerAPI .post('/Partner/FromToMarketCoinSwap', {
      swap: {
        fromCoinID: modelSwap.fromCoinID,
        fromAmount: modelSwap.fromAmount,
        toCoinID: modelSwap.toCoinID,
      }
    },config).then(result => {
      setAmountPara(result.data.toAmount);
      console.log(result, 'localizarSaldoCrypto PARA')
      setLoadingMsgPara(false);
    }
    ).catch(error => {
      console.log(error.response, 'localizarSaldoCrypto')
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });
  }

  const handleConfirmaSwap = async() => {
    console.log('entrou handleConfirmaSwap');

    if(balanceDe === 0){
      toast.warning('Sem Saldo para realizar Transação', {
        position: 'top-right'
      });

      return;
    }

    if(amountDe === "0.0"){
      toast.warning('Insira um valor para o Swap', {
        position: 'top-right'
      });

      return;
    }
    setHabilitadoBtn(false);

    setColorDisableBtn("grey") 

    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    setTimeout(async() => {
      const result = await partnerAPI.post("/Partner/SwapCrypto",{
          cryptoUserID: sessionStorage.getItem("uid"),
          hashReceiver: texEndereco,
          swap: {
            fromCoinID: coinDe,
            fromAmount: amountDe,
            toCoinID: coinPara,
            toAmount: amountPara
          }
        }, config
      ).then((result) => {

        console.log(result, "/Partner/SwapCrypto");

        if(result.data.retorno === "Swap Cadastrado com Sucesso"){
          toast.success('Swap em Processamento', {
            position: 'top-right'
          });
        }

      }).catch((error) => {
        console.log(error.response, "/Partner/SwapCrypto");
        if (error.response.status === 401) {
          //setAuthenticated(false);
        }
      });

      console.log(result, 'result SwapCrypto')
      setHabilitadoBtn(true);
      setColorDisableBtn("#D4AA51") 
      localizarSaldoCryptoDe(coinDe);
      localizarSaldoCryptoPara(coinPara);
    }, 2000);

  };

  const handleInputChangeDe = (event) => {
    //debugger;
    // Verificar se o valor digitado é um número inteiro ou decimal
    const inputValue = event.target.value;
  
    
    // A expressão regular permite dígitos e um ponto opcional
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setAmountDe(inputValue);

      if(event.target.value !== "" || event.target.value === "0.0"){
        modelSwap.fromAmount = event.target.value;
        console.log(modelSwap,'modelSwap ConverteDeParaMercadoCriptomoeda')
        ConverteDeParaMercadoCriptomoeda(modelSwap);
      }
    }
  };

  const handleInputChangePara = (event) => {
    // Verificar se o valor digitado é um número inteiro ou decimal
    //debugger;
    const inputValue = event.target.value;

    console.log(modelSwap, 'modelSwap handleInputChangePara')

    // A expressão regular permite dígitos e um ponto opcional
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setAmountPara(inputValue);

    }
  };

  return (
    <div className="row col-sm-12" style={{ padding: 10, paddingBottom: 89 }}>
      <Card
        className="col-sm-4"
        style={{
          height: "480px",
          marginLeft: 10,
        }}
      >
        <CardContent
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",

            
          }}
        >
          <div className="col-sm-12" style={{marginBottom: 15}}>
            <h4>
              Cipay Swap
            </h4>
            <div className="text-left">
              <p>Troque criptomoedas em tempo real.</p>
            </div>
          </div>
          <div className="text-center">

            <Paper elevation={3} style={{padding: 10, backgroundColor: '#f1f1f3'}}>
              <div className="row">
                <div className="col-sm-6">

                  <FormControl className="col-sm-12" variant="standard">

                    {
                      loadingDe === false ? 
                      <>
                        <div style={{marginTop:10}}>
                          <TextField
                            label="De"
                            value={amountDe}
                            onChange={(e)=> handleInputChangeDe(e)}
                            variant="standard"
                          />
                        </div>
                      </> : 
                      <>

                        <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: "absolute",
                              top: "50%",
                              left: "34%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                        />
                      </>
                    }


                    

                  </FormControl>

                </div>
                <div className="col-sm-6">

                  <FormControl className="col-sm-12" variant="standard">
                    <InputLabel id="coin-controller-label" value={coinDe}>
                      Criptomoeda
                    </InputLabel>
                    <Select
                      
                      labelId="coin-controller-label"
                      id="coin-controller-label"
                      open={openCoinDe}
                      onClose={handleCloseCoinDe}
                      onOpen={handleOpenCoinDe}
                      value={coinDe}
                      label="Coin"
                      onChange={(e) => handleChangeCoinDe(e)}
                      style={{marginTop:25}}
                    >
                      {!saqueSelect ? (
                        <div style={{ padding: 5 }}>
                          <CircularProgress color="secondary" />
                        </div>
                      ) : (
                        listCrypto.walletCryptosPessoa.map((crypto, index) => (
                          <MenuItem key={index} value={crypto.cdCrypto}>
                              <div className="d-flex" style={{ }}>
                                <div style={{alignItems:"center"}} >
                                    <Avatar alt="Remy Sharp" style={{width:23 , height:23, marginRight:20}} src={crypto.urlImgCrypto} />
                                </div>
                                  {crypto.eliasCrypto}
                              </div>
                          </MenuItem>
                        ))
                      )}
                    </Select>

                  </FormControl>

                </div>
              </div>
            </Paper>
          </div>

          <div>
            <div className="row" style={{paddingLeft: 10 }}>
              <div className="col-sm-2">

                <p>Saldo:</p>
              </div>
              <div className="col-sm-6">
                {
                  balanceDe
                }
              </div>
            </div>
            <div className="text-center" style={{ marginBottom: 15}}>
              <IconButton size="large">
                <ImportExportIcon/>
              </IconButton>
            </div>
          
          </div>
          
          <div className="text-center">
            <Paper elevation={3} style={{padding: 10, backgroundColor: '#f1f1f3'}}>
              <div className="row">
                <div className="col-sm-6">


                  <FormControl className="col-sm-12" variant="standard">
                    {
                      loadingPara === false ? 
                      <>
                        
                        <div style={{marginTop:7}}>
                          <TextField
                            label="Para"
                            value={amountPara}
                            onChange={handleInputChangePara}
                            variant="standard"
                          />
                        </div>

                      </> : 
                      <>
                      <div style={{paddingTop: 13}}>

                        <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: "absolute",
                              left:15
                            }}
                        />
                      </div>
                      </>
                    }


                  </FormControl>
                </div>
                <div className="col-sm-6">

                  <FormControl className="col-sm-12" variant="standard">
                    <InputLabel   id="coin-controller-label" value={coinPara}>
                      Criptomoeda
                    </InputLabel>
                    <Select
                      ref={selectRef}
                      labelId="coin-controller-label"
                      id="coin-controller-label"
                      open={openCoinPara}
                      onClose={handleCloseCoinPara}
                      onOpen={handleOpenCoinPara}
                      value={coinPara}
                      label="Coin"
                      onChange={(e) => handleChangeCoinPara(e)}
                      onBlur={handleChangeCoinPara}
                      style={{marginTop:22}}
                    >
                      {!saqueSelect ? (
                        <div style={{ padding: 5 }}>
                          <CircularProgress color="secondary" />
                        </div>
                      ) : (
                        listCrypto.walletCryptosPessoa.map((crypto, index) => (
                          <MenuItem key={index} value={crypto.cdCrypto}>
                            <div className="d-flex" style={{}} >
                              <div style={{alignItems:"center"}} >
                                  <Avatar alt="Remy Sharp" style={{width:23 , height:23, marginRight:20}} src={crypto.urlImgCrypto} />
                              </div>
                              {crypto.eliasCrypto}
                            </div>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>

                </div>
              </div>
            </Paper>
          </div>

          <div>
            <div className="row" style={{paddingLeft: 10, paddingTop: 3}}>
              <div className="col-sm-2">
                <p>Saldo:</p>
              </div>
              <div className="col-sm-6">
              {
                balancePara
              }
              </div>
            </div>
          </div>

          <div className="my-2" style={{paddingBottom: 5, marginBottom: 50 }}>
              <Button
                ref={buttonRef} 
                className="col-md-12"
                variant="outlined"
                onClick={handleConfirmaSwap}
                disabled={!habilitadoBtn}
                style={{
                  backgroundColor: colorDisableBtn,
                  color: "black",
                  borderBlockColor: "aliceblue",
                }}
              >
                Confirmar

                {loadingMsg && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "34%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
          </div>
        </CardContent>
      </Card>

      <div className="col-sm-4">
        <p className="display-6" style={{ padding: 10 }}>
          Cotação
        </p>
        <div className="text-left" style={{ padding: 10 }}>
          <p>Observe que esta é a taxa final de cotação da conversão, que está sujeita às condições de mercado e que pode 
            não ser igual ao preço spot. O preço pode ser atualizado. </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
