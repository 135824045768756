import axios from 'axios';

const bankAPI = axios.create({
    baseURL: 'https://apigatewaycipay.azure-api.net/corporate/',
    //baseURL: 'https://localhost:44313/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials':true
    },
    //baseURL: 'http://10.6.227.51:44313'
})

// api.addResponseTransform(response => {
//     if(!response.ok) throw response
//
//baseURL: 'http://bank.servehttp.com:30000' })

export default bankAPI; 

