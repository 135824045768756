import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useContext } from "react";
import authContext from "../../../Context/authContext";

import partnerApi from "../../../Services/partnerAPI";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import partnerAPI from "../../../Services/partnerAPI";

export default function Cripto() {
  const { authenticated, setAuthenticated } = useContext(authContext);
  const [listCrypto, setlistCrypto] = useState([]);
  const [listCryptoInWallet, setListCryptoInWallet] = useState([]);
  const [isLoadedWallet, setIsLoadedWallet] = useState(false);
  const [isLoadedCryptos, setIsLoadedCrypto] = useState(false);

  useEffect(() => {
    loadCryptos();
    loadCryptosInWallet();
  }, []);

  const loadCryptosInWallet = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      },
    };

    await partnerAPI    
      .post(
        "/Partner/WalletPerson",
        {
          cryptoUserID: window.sessionStorage.getItem("uid"),
        },
        config
      )
      .then((result) => {
        setListCryptoInWallet(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error.response, "walletPessoa WalletPessoaList");
      });
    setIsLoadedWallet(true);
  };

  const loadCryptos = async () => {
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    try {
      const result = await partnerAPI.post(
        "/Partner/List/Add/Crypto",
        {
          cryptoUserID: sessionStorage.getItem("uid"),
        },
        config
      );
      setlistCrypto(result.data);
      console.log(result.data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    }
    setIsLoadedCrypto(true);
  };

  const handleInsertCryptoInWallet = async (idCrypto) => {
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };
    setIsLoadedCrypto(false);
    setIsLoadedWallet(false);

    try {
      const result = await partnerApi.post(
        "/Partner/InsertCryptoWallet",
        {
          idCrypto: idCrypto,
          idCryptoUser: sessionStorage.getItem("uid"),
        },
        config
      );

      await loadCryptosInWallet();
      await loadCryptos();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCryptoWallet = async (idCrypto) => {
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };
    setIsLoadedCrypto(false);
    setIsLoadedWallet(false);
    try {
      const result = await partnerApi.post(
        "Partner/RemoveCryptoWallet",
        {
          id: idCrypto,
          idCrypto: idCrypto,
          idCryptoUser: sessionStorage.getItem("uid"),
        },
        config
      );

      await loadCryptosInWallet();
      await loadCryptos();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row col-sm-12" style={{ padding: 10, paddingBottom: 89 }}>
      <Card
        className="col-sm-4"
        style={{
          height: "414px",
          marginLeft: 10,
          overflowY: "auto",
        }}
      >
        <CardContent style={{ padding: 20 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label="Criptomoeda"
              variant="standard"
            />
          </Box>
          <Box>
            <Typography my={2} fontWeight={"bold"}>
              Sua Carteira
            </Typography>
            {isLoadedWallet ? (
              listCryptoInWallet.walletCryptosPessoa.map((cripto, index) => (
                <Box key={index} display="flex" gap="10px">
                  <Card sx={{ marginY: "5px", width: "90%" }}>
                    <CardActionArea>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingX: "10px",
                          height: "35px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={cripto.urlImgCrypto}
                          sx={{ height: "25px", width: "25px" }}
                        />
                        <CardContent sx={{ padding: "0px !important" }}>
                          {cripto.eliasCrypto}
                        </CardContent>
                      </Box>
                    </CardActionArea>
                  </Card>
                  <IconButton
                    onClick={() => handleDeleteCryptoWallet(cripto.cdCrypto)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={100}
              >
                <CircularProgress size={60} color="secondary" />
              </Box>
            )}
            <Typography my={2} fontWeight={"bold"}>
              Cryptos Disponíveis
            </Typography>
            {isLoadedCryptos ? (
              listCrypto.map((cripto, index) => (
                <Box key={index} display="flex" gap="10px">
                  <Card sx={{ marginY: "5px", width: "90%" }}>
                    <CardActionArea>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingX: "10px",
                          height: "35px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={cripto.urlImgCrypto}
                          sx={{ height: "25px", width: "25px" }}
                        />
                        <CardContent sx={{ padding: "0px !important" }}>
                          {cripto.nmCrypto}
                        </CardContent>
                      </Box>
                    </CardActionArea>
                  </Card>
                  <IconButton
                    onClick={() => handleInsertCryptoInWallet(cripto.idCrypto)}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={100}
              >
                <CircularProgress size={60} color="secondary" />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <div className="col-sm-4">
        <p className="display-6" style={{ padding: 10 }}>
          Adicione uma criptomoeda
        </p>
        <div className="text-left" style={{ padding: 10 }}>
          <p>
            Basta clicar na criptomoeda desejada que ela será adicionada em sua
            carteira.
          </p>
        </div>
      </div>
    </div>
  );
}
