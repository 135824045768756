import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import authContext from "../../../Context/authContext";


//material
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Button, Fade, Modal, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from "@mui/material/Backdrop";
import partnerAPI from "../../../Services/partnerAPI";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 12,
    p: 4,
    textAlign: "center",
  };

export default function Ajuda(props) {
  useEffect(() => {
    carregarPerguntaResposta();
    marginbottomdynamic();
  }, []);

  const navigate = useNavigate();
  const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(267);
  const { authenticated, setAuthenticated } = useContext(authContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [listPergunta, setPerguntasRespostas] = useState({
    perguntaRespostas: [],
  });

  const marginbottomdynamic = () => {
    ////console.log(state.state.eliasCrypto, 'state uselocation')
    if (window.screen.width === 1920 && window.screen.height === 1080) {
      setDynamicPaddingBottom(310);
    } else {
      setDynamicPaddingBottom(380);
    }
  };

  const carregarPerguntaResposta = async () => {
    // // console.log(await AsyncStorage.getItem(tokenHeader))
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    partnerAPI
      .post("/Partner/QuestioResponse", {}, config)
      .then((result) => {
        console.log(result.data, "carregarPerguntaResposta");
        setPerguntasRespostas(result.data);
        setDynamicPaddingBottom(70);
        // console.log(listPergunta2,'listPergunta2');
      })
      .catch((error) => {
        // console.log(error.response, 'catch')
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
      });
  };

  return (
    <div className="my-5" style={{ margin: 10 }}>
      <div className="display-6 my-5" style={{ margin: 5 }}>
        Me Ajuda
      </div>

      {
        <Box
          className="col-sm-7"
          style={{ paddingBottom: dynamicPaddingBottom }}
        >
          {listPergunta.perguntaRespostas.map((ajuda, index) => (
            <Accordion
              key={index}
              style={{ margin: 5 }}
              onClick={() => console.log("Ajuda")}
              defaultExpanded={false}
            >
              <AccordionSummary
                key={index}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{ajuda.pergunta}</Typography>
              </AccordionSummary>

              <AccordionDetails key={index}>
                <div>{ajuda.resposta}</div>
                <div style={{ textAlign: "center", margin: "10px 0" }}>
                  Essa resposta te ajudou?
                </div>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CheckOutlinedIcon sx={{ color: "black" }} />
                    Sim
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpen()}
                  >
                    <CloseOutlinedIcon sx={{ color: "black" }} />
                    Não
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              m={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Box>
                <Typography variant="h3">Ajuda</Typography>
              </Box>
              <Box mr={2}>
                <Button onClick={() => handleClose()}><CloseIcon sx={{color: "black"}} /></Button>
              </Box>
            </Box>
            <Box m={2}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box m={2}>
              <TextField
                id="outlined-basic"
                label="Assunto"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box m={2}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={3}
                label="Mensagem"
                fullWidth
              />
            </Box>
            <Box>
              <Button variant="outlined" color="secondary">
                Enviar
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
