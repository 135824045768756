import React, { useContext, useState } from "react";
import { useNavigate, BrowserRouter as Router } from "react-router-dom";

//material
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CardActionArea from "@mui/material/CardActionArea";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import AddIcon from "@mui/icons-material/Add"; // adicionar
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined"; //queimar
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';

export default function ListCashBack(props) {
  const navigate = useNavigate();
  const [off, setOff] = useState(true);

  return (
    <div className="my-5 ">
      <div style={{ marginTop: 10 }}>
        <div style={{ marginBottom: 25 }}>
          <h5>Configurações do Cashback</h5>
        </div>
        <Box
          className="listaConfig"
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 8,
          }}
        >
          <List>
            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/dados")}>
                <ListItemButton>
                  <MonetizationOnOutlinedIcon />
                  <ListItemText
                    primary="Dados do Token"
                    className="col-sm-6"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/adicionarTokens",{
                state:{
                  type:0,

                },
              })}>
                <ListItemButton>
                  <AddIcon />
                  <ListItemText
                    primary="Adicionar Tokens"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/queimarTokens",{
                state:{
                  type:1,
                },
              })}>
                <ListItemButton>
                  <WhatshotOutlinedIcon />
                  <ListItemText
                    primary="Queimar Tokens"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/transacao")}>
                <ListItemButton>
                  <CurrencyExchangeIcon />
                  <ListItemText
                    primary="Adicionar Transações"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/transacao")}>
                <ListItemButton>
                  <BlockOutlinedIcon />
                  <ListItemText
                    primary="Bloquear Transaçôes"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/contratoInteligente")}>
                <ListItemButton>
                  <TipsAndUpdatesOutlinedIcon />
                  <ListItemText
                    primary="Contrato Inteligente"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/controleDeFidelidade")}>
                <ListItemButton>
                  <HandshakeOutlinedIcon/>
                  <ListItemText
                    primary="Controle de Fidelidade"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>
          </List>
        </Box>
      </div>
    </div>
  );
}
