import React, { useState, useEffect, useContext } from "react";
import partnerAPI from "../../../Services/partnerAPI";
import authAPI from "../../../Services/authAPI";
import bankAPI from "../../../Services/bankAPI";
import smartContractAPI from "../../../Services/smartContractAPI";
import authContext from "../../../Context/authContext";
import navContext from "../../../Context/navContext";
import firstLoginWhiteLabelContext from "../../../Context/firstLoginWhiteLabelContext";
import { useNavigate } from "react-router-dom";

//material
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import CardActionArea from "@mui/material/CardActionArea";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

export default function Token() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [NameToken, setNameToken] = useState("");
  const [NameTokenErro, setNameTokenErro] = useState("");

  const [AmountTotal, setAmountTotal] = useState();
  const [AmountTotalErro, setTAmountTotalErro] = useState("");

  const [UrlImageToken, setUrlImageToken] = useState("");
  const [UrlImageTokenErro, setUrlImageTokenErro] = useState("");

  const [UrlWebSiteToken, setUrlWebSiteToken] = useState("");
  const [UrlWebSiteTokenErro, setUrlWebSiteTokenErro] = useState("");

  const [Description, setDescription] = useState("");
  const [DescriptionErro, setDescriptionErro] = useState("");

  const [txtEmail, setTxtEmail] = useState("");
  const [txtEmailErro, setTxtEmailErro] = useState("");

  const [txtSenha, setTxtSenha] = useState("");
  const [txtSenhaErro, setTxtSenhaErroErro] = useState("");

  const [txtConfirmacaoSenha, setTxtConfirmacaoSenha] = useState("");
  const [txtConfirmacaoSenhaErro, setTxtConfirmacaoSenhaErro] = useState("");

  const [isFinished, setIsFinished] = useState(false);

  const [checarCamposStep3, setChecarCamposStep3] = useState(false);
  const [exceptionStep3, setExceptionStep3] = useState("");

  const steps = [
    "Informações do Token",
    "Criação Carteira do Token",
    "Confirmações",
  ];

  const { authenticatedNav, setAuthenticatedNav } = useContext(navContext);
  const { authenticated, setAuthenticated } = useContext(authContext);
  const { isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel } = useContext(
    firstLoginWhiteLabelContext
  );

  useEffect(() => {
    setTxtEmail(sessionStorage.getItem("email"));
  }, []);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const [cadastroToken, setCadastroToken] = useState({
    nameAssets: "string",
    balanceAssets: 0,
    urlAssets: "string",
    urlWebSiteAssets: "string",
    descriptionAssets: "string",
    login: {
      email: "string",
      password: "string",
    },
  });

  const handleNext = async () => {
    let newSkipped = skipped;
    console.log(activeStep, "activeStep handleNext");

    if (isStepSkipped(activeStep)) {
      console.log("isStepSkipped(activeStep)");
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      if (checkCampos(activeStep)) {
        console.log("entrou if(activeStep === 0)");
        return;
      }
    }

    if (activeStep === 1) {
      console.log("entrou if(activeStep === 1)");
      if (checkCampos(activeStep)) {
        return;
      }
    }

    //cadastro de token
    if (activeStep === 2) {
      setIsFinished(true);
      console.log(UrlImageToken, "txtImageToken activeStep === 2");
      const cadastro = await finalizarCadastroToken({
        NameToken: NameToken,
        AmountTotal: AmountTotal,
        UrlImageToken: UrlImageToken,
        UrlWebSiteToken: UrlWebSiteToken,
        Description: Description,
        login: {
          email: txtEmail,
          password: txtConfirmacaoSenha,
        },
      });

      if (cadastro.success === false) return;

      console.log(cadastro, "existeCadastroRealizado");

      // if(cadastro == 'Falha ao criar contrato'){
      //     setExceptionStep3('E-mail existente');
      //     setChecarCamposStep3(true);
      //     setTimeout(() => {
      //         setChecarCamposStep3(false);
      //     }, 3000)
      //     return;
      // }

      // if(cadastro == 'contrato existente'){
      //     setChecarCamposStep3(true);
      //     setExceptionStep3('Nome do token existente');
      //     setTimeout(() => {
      //         setChecarCamposStep3(false);
      //     }, 3000)
      //     return;
      // }

      //console.log('cadastroTokenRequest')
      // if(cadastroTokenRequest.msg === "contrato criado com sucesso"){
      //     console.log("token criado com sucesso")
      // }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const checkCampos = (stepNumber) => {
    let checkInput = false;

    if (stepNumber === 0) {
      if (NameToken === "") {
        setNameTokenErro("Campo vazio ou formato invalido.");
        checkInput = true;
      } else setNameTokenErro("");

      if (AmountTotal === "" || AmountTotal <= 0 || AmountTotal === undefined) {
        setTAmountTotalErro("Campo deve ser maior que zero");
        checkInput = true;
      } else setTAmountTotalErro("");

      if (UrlImageToken === "") {
        setUrlImageTokenErro("Campo obrigatorio.");
        checkInput = true;
      } else setUrlImageTokenErro("");

      if (UrlWebSiteToken === "") {
        setUrlWebSiteTokenErro("Campo obrigatorio.");
        checkInput = true;
      } else setUrlWebSiteTokenErro("");

      if (!handleUrlWebSiteToken(UrlWebSiteToken)) {
        setUrlWebSiteTokenErro("Digite uma url válida");
        checkInput = true;
      } else setUrlWebSiteTokenErro("");

      if (!handleUrlWebSiteToken(UrlImageToken)) {
        setUrlImageTokenErro("Digite uma url válida");
        checkInput = true;
      } else setUrlImageTokenErro("");

      if (Description === "") {
        setDescriptionErro("Campo obrigatorio.");
        checkInput = true;
      } else setDescriptionErro("");
    }

    if (stepNumber === 1) {
      if (!isValidEmail(txtEmail)) {
        setTxtEmailErro("Digite um e-mail válido");
        console.log("email invalido");
        checkInput = true;
      } else {
        setTxtEmailErro("");
      }
      if (txtSenha === "") {
        setTxtSenhaErroErro("Campo obrigatorio.");
        checkInput = true;
      } else setTxtSenhaErroErro("");

      if (txtConfirmacaoSenha === "" || txtSenha !== txtConfirmacaoSenha) {
        setTxtConfirmacaoSenhaErro("Campo vazio ou senha incorreta.");
        checkInput = true;
      } else setTxtConfirmacaoSenhaErro("");
    }

    return checkInput;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email);
  };

  const upLoadImages = () => {
    let input = document.createElement("input");
    input.type = "file";
    console.log(input, "input fileObject");

    input.onchange = (_) => {
      const fileObject = Array.from(input.files);
      console.log(fileObject, "fileObject");

      if (
        fileObject[0].type === "image/png" ||
        fileObject[0].type === "image/jpeg" ||
        fileObject[0].type === "image/webp"
      ) {
        convertImageToBase64(fileObject);
        return;
      }
    };

    input.click();
  };

  const convertImageToBase64 = (formObject) => {
    //console.log(formObject, 'formObject');
    Promise.all(
      formObject.map(
        (image) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (file) => {
              resolve(file.target.result.split(",")[1]);
            };

            fileReader.onerror = (error) => reject(error);
            fileReader.readAsDataURL(image);
          })
      )
    ).then((base64Images) => {
      console.log(base64Images, "base64Images");

      const config = {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      };

      bankAPI
        .post(
          "/Imagens/UploadImgbb",
          {
            imagemBase64: base64Images[0],
          },
          config
        )
        .then((result) => {
          console.log(result, "/Imagens/UploadImgbb");
          setUrlImageToken(result.data.data.url);
        })
        .catch((error) => {
          console.log(error.response, "catch finalizarCadastroToken");
        });
    });
  };

  const atualizarSenhaWallet = (cadastroBody) => {
    return;
    authAPI
      .post("/Contract/create", cadastroToken)
      .then((result) => {})
      .catch((error) => {
        console.log(error.response, "walletPessoa");

        if (error.response.status === 401) {
        }
      });
  };

  const finalizarCadastroToken = async (cadastroBody) => {
    let response;
    console.log(cadastroBody, "cadastroBody finalizarCadastroToken");
    setCadastroToken(cadastroBody);
    //console.log(cadastroToken,' cadastroToken finalizarCadastroToken')

    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    await smartContractAPI
      .post(
        "/Contract/create",
        {
          NameToken: cadastroBody.NameToken,
          AmountTotal: cadastroBody.AmountTotal,
          UrlImageToken: cadastroBody.UrlImageToken,
          UrlWebSiteToken: cadastroBody.UrlWebSiteToken,
          Description: cadastroBody.Description,
          login: {
            email: cadastroBody.login.email,
            password: cadastroBody.login.password,
          },
        },
        config
      )
      .then((result) => {
        console.log(result, "smartContractAPI");

        if (result.data.success === true) {
          setAuthenticatedNav(true);
          setAuthenticated(true);
          setIsFirstLoginWhiteLabel(false);
          navigate("/home");

          response = result.data;
        } else {
          let text = "";
          for (let prop in result.data.erros) {
            text += `${prop} : ${result.data.erros[prop]}` + "\n";
          }
          window.alert(text);
          response = result.data;
        }
      })
      .catch((error) => {
        console.log(error.response, "catch finalizarCadastroToken");

        if (error.response.status === 401) {
        }
      });

    return response;
  };

  const handleNameToken = (value) => {
    const containNumber = /\d/.test(value);

    if (containNumber) {
      setNameTokenErro("O nome do token não pode conter números");
    } else {
      setNameToken(value);
      setNameTokenErro("");
    }
  };

  const handleAmountTotal = (value) => {
    const containOnlyNumber = /^\d+$/.test(value);

    if (containOnlyNumber) {
      setAmountTotal(value);
      setTAmountTotalErro("");
    } else {
      setTAmountTotalErro("Não pode ter valores negativos");
    }
  };

  const handleUrlWebSiteToken = (value) => {
    if (isUrlValid(value)) {
      return true;
    } else {
      return false;
    }
  };

  const isUrlValid = (url) => {
    var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    return urlRegex.test(url)
  };

  return (
    <div className="container my-5">
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps} style={{ padding: 10 }}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <div className="container col-sm-8">
              <div>
                <Card>
                  <CardContent className="text-center">
                    <div style={{ padding: 10, paddingBottom: 90 }}>
                      <Typography variant="h6" gutterBottom>
                        Parabens seu Token foi Criado com sucesso
                      </Typography>
                      <div className="my-5">
                        <img src={UrlImageToken} className="col-sm-2" alt="" />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              {!isFinished && <Button onClick={handleReset}>Resetar</Button>}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="container col-sm-9 my-2">
              {activeStep === 0 && (
                <Card>
                  <CardContent>
                    <div className="my-2" style={{ paddingLeft: 20 }}>
                      <h2 className="text-left">Criação do token</h2>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div
                          className="row"
                          style={{ alignItems: "center", marginTop: "10px" }}
                        >
                          <div className="col-sm-9">
                            <TextField
                              required
                              fullWidth
                              label="Nome Token"
                              id="outlined-basic"
                              variant="standard"
                              value={NameToken}
                              error={Boolean(NameTokenErro)}
                              helperText={NameTokenErro}
                              onChange={(e) => handleNameToken(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-1">
                            <Tooltip title="Nome que será reconhecido o Token">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ alignItems: "center", marginTop: "10px" }}
                        >
                          <div className="col-sm-9">
                            <TextField
                              required
                              fullWidth
                              label="Quantidade Moedas"
                              type="number"
                              id="outlined-basic"
                              variant="standard"
                              value={AmountTotal}
                              onChange={(e) =>
                                handleAmountTotal(e.target.value)
                              }
                              error={Boolean(AmountTotalErro)}
                              helperText={AmountTotalErro}
                            />
                          </div>
                          <div className="col-sm-1">
                            <Tooltip title="Quantidade de dinheiro para capitalização de recurso">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ alignItems: "center", marginTop: "10px" }}
                        >
                          <div className="col-sm-9">
                            <TextField
                              required
                              fullWidth
                              label="URL do token"
                              id="outlined-basic"
                              variant="standard"
                              value={UrlWebSiteToken}
                              onChange={(e) =>
                                setUrlWebSiteToken(e.target.value)
                              }
                              error={Boolean(UrlWebSiteTokenErro)}
                              helperText={UrlWebSiteTokenErro}
                            />
                          </div>
                          <div className="col-sm-1">
                            <Tooltip title="Site que contém todas as informações do token">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ alignItems: "center", marginTop: "10px" }}
                        >
                          <div className="col-sm-9">
                            <TextField
                              required
                              fullWidth
                              multiline
                              label="URL Imagem Hospedada"
                              id="outlined-basic"
                              variant="standard"
                              value={UrlImageToken}
                              onChange={(e) => setUrlImageToken(e.target.value)}
                              error={Boolean(UrlImageTokenErro)}
                              helperText={UrlImageTokenErro}
                            />
                          </div>
                          <div className="col-sm-1">
                            <Tooltip title="URL da imagem que esta em algum servidor">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ alignItems: "center", marginTop: "10px" }}
                        >
                          <div className="col-sm-9">
                            <TextField
                              fullWidth
                              multiline
                              label="Descrição Token"
                              id="outlined-basic"
                              variant="standard"
                              value={Description}
                              onChange={(e) => setDescription(e.target.value)}
                              error={Boolean(DescriptionErro)}
                              helperText={DescriptionErro}
                            />
                          </div>
                          <div className="col-sm-1">
                            <Tooltip title="Descreva sobre o token">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="col-sm-9">
                          <p className="text-justify">
                            Tokenizamos produtos e serviços. Arte digital,
                            colecionáveis e até ingressos podem ser negociados
                            na forma de NFTs para empresas captarem recursos ou
                            promoverem ações publicitárias.
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}

              {activeStep === 1 && (
                <div>
                  <Card style={{ paddingBottom: 110 }}>
                    <CardContent>
                      <div className="my-2" style={{ paddingLeft: 20 }}>
                        <h2 className="text-left">Carteira</h2>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div style={{ margin: 10 }}>
                            <TextField
                              className="col-sm-12"
                              label="E-mail"
                              id="outlined-basic"
                              variant="standard"
                              value={txtEmail}
                              onChange={(e) => setTxtEmail(e.target.value)}
                              error={Boolean(txtEmailErro)}
                              helperText={txtEmailErro}
                            />
                          </div>
                          <div style={{ margin: 10 }}>
                            <TextField
                              className="col-sm-12"
                              label="Senha"
                              type="password"
                              variant="standard"
                              value={txtSenha}
                              onChange={(e) => setTxtSenha(e.target.value)}
                              error={Boolean(txtSenhaErro)}
                              helperText={txtSenhaErro}
                            />
                          </div>
                          <div style={{ margin: 10 }}>
                            <TextField
                              className="col-sm-12"
                              label="Confirmação de senha"
                              type="password"
                              variant="standard"
                              value={txtConfirmacaoSenha}
                              onChange={(e) =>
                                setTxtConfirmacaoSenha(e.target.value)
                              }
                              error={Boolean(txtConfirmacaoSenhaErro)}
                              helperText={txtConfirmacaoSenhaErro}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col-sm-10">
                            <p className="text-justify">
                              Esta será sua carteira, Não esqueça sua senha. Por
                              questões de segurança não anote em folhas e bloco
                              de nota memorize na memoria ;)
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}

              {activeStep === 2 && (
                <div>
                  <Card style={{ paddingBottom: 100 }}>
                    <CardContent>
                      <div className="container col-sm-8">
                        <div className="row">
                          <div className="col-sm-6">
                            <h4>Token</h4>
                            <p>{NameToken}</p>
                          </div>
                          <div className="col-sm-6">
                            <h4>Logo do token</h4>
                            <img
                              src={UrlImageToken}
                              className="col-sm-2"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <h4>Quantidade de moedas</h4>
                            <p>{AmountTotal}</p>
                          </div>

                          <div className="col-sm-6">
                            <h4>Site do token</h4>
                            <p>{UrlWebSiteToken}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-7">
                            <div>
                              <h4>Informações do Token</h4>
                              <p>{Description}</p>
                            </div>
                          </div>
                          <div className="col-sm-5">
                            {checarCamposStep3 && (
                              <div style={{ margin: 5 }}>
                                <p style={{ color: "red" }}>{exceptionStep3}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}
            </div>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Voltar
              </Button>

              <Box sx={{ flex: "1 1 auto" }} />

              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finalizar" : "Avançar"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}
