import React, {useContext, useState} from 'react'
import { useNavigate ,BrowserRouter as Router} from 'react-router-dom';


//material
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';


export default function CadastroDistribuidor(props){

    const [txtRetability, setTxtRetability] = useState('')
    const [txtClosingMonth, setTxtClosingMonth] = useState('')
    const [txDatePayments, setTxtDatePayments] = useState(new Date);
    const [txtIPCA, setTxtIPCA] = useState(false)
    const [txtActive, setTxtActive] = useState('')

    const label = { inputProps: { 'aria-label': 'Size switch demo' } };

    return (

        <div className='container my-5 card col-sm-9' >
            
            <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                <h5>Cadastro de Distribuidor</h5>
            </div>

            <div className='container' style={{marginBottom: 10}}>
                <div className='row border border-2' style={{marginBottom: 15, borderRadius: 20, paddingBottom: 150}}>
                    <div className='col-sm-6 my-3'>
    
                        <div className='row' >
                            <div className='col-sm-2 text-center' >
                                <div style={{marginTop: 5}}>
                                    <p>Ativo </p>
                                </div>
                            </div>
                            <div className='col-sm-6' >
                                <Switch {...label} defaultChecked />
                            </div>
                        </div>
                        
                    </div>

                    
                </div>
            </div>


        </div>
    );
}