import React, {useEffect, useState} from 'react'

//material
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import partnerAPI from '../../../Services/partnerAPI';

export default function DadosToken(props){

    //#region Data
    const [dataName, setDataName] = useState('')
    const [dataAmount, setDataAmount] = useState(0)
    const [dataDescription, setDataDescription] = useState('')
    const [contract, setContract] = useState('')
    const [disableUpdate, setDisableUpdate] = useState(true);


    


    useEffect(() => {
        const fetchData = async () => {
          try {
            let contract = sessionStorage.getItem('contract');     
            let uid = sessionStorage.getItem('uid');         
            setContract(contract);
            const config = {
                headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
                },
            };
                        
            partnerAPI.get(`Partner/DadosToken_GetData?contract=${contract}&uid=${uid}`,config)
            .then((res) => {
                

                if(res.data.success === false){
                    let text = "";
                    for(let prop in res.data.erros){
                        text += `${prop} : ${res.data.erros[prop]}` + "\n";
                    }                
                    window.alert(text);                                                 
                }else{
                    const dataReturn = JSON.parse(res.data.data);
                    console.log(dataReturn, 'SUCCESS');

                    setDataName(dataReturn.Name);
                    setDataDescription(dataReturn.Description);
                    setDataAmount(dataReturn.Amount);
                    
                    // Logica de bloqueio entra aqui
                    setDisableUpdate(dataReturn.FlagDisableUpdate)

                }
            })            
        .catch(error => {
            window.alert('Ocorreu algum erro no processamento!');                
            console.log(error.response, 'ERRO');
        });
          } catch (error) {
            console.error('Error:', error);
          }
        };
    
        fetchData();
      }, []);

    //#endregion   
    
    const handleClickEnviar = () => {
                
        if(dataName === null || dataName === ""){
            return window.alert('O campo Nome não pode estar vazio');
        }
        if(dataAmount === null || dataAmount === 0){
            return window.alert('O campo Quantidade não pode estar zerado');
        }
        
        if(contract === null || contract === ""){
            return window.alert('Acesso não permitido para fazer determinada ação.');
        }

        var data = {
            'Name': dataName,
            'Amount': dataAmount,
            'Description': dataDescription,
            'ContractHash': contract
        };
        
        // agora fica para fazer a requisição para o back-end   
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };     

        partnerAPI.post("Partner/DadosToken_Update", data,config)
        .then((res) => {
            console.log(res.data, 'SUCCESS');

            if(res.data.success === false){
                let text = "";
                for(let prop in res.data.erros){
                    text += `${prop} : ${res.data.erros[prop]}` + "\n";
                }                
                window.alert(text);                                                 
            }else{
                window.alert('Fluxo processado com sucesso!');                                
            }
        })            
        .catch(error => {
            window.alert('Ocorreu algum erro no processamento!');                
            console.log(error.response, 'ERRO');
        });

      };

    return (
        <div style={{margin:'30px'}}>
        <div className='container card col-sm-6' style={{marginBottom: 100}}>        
            
            <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                <h5>Dados do Ativo</h5>
            </div>

            <div className='container' style={{marginBottom: 10}}>               
                <Box component="form"  noValidate autoComplete="off"  >

                    <div style={{marginBottom: 10}}>
                        <TextField className='col-sm-12'
                            label="Nome" 
                            variant="standard" 
                            value={dataName} 
                            onChange={e => setDataName(e.target.value)} 
                            disabled={disableUpdate}
                        />
                    </div>
                    <div style={{marginBottom: 10}}>
                        <TextField className='col-sm-12'
                            type="number"
                            label="Quantidade de token" 
                            variant="standard" 
                            value={dataAmount} 
                            onChange={e => setDataAmount(e.target.value)} 
                            disabled={disableUpdate}
                        />
                    </div>
                    <div style={{marginBottom: 10}}>
                        <TextField className='col-sm-12'                                
                            multiline
                            maxRows={10}
                            label="Descrição" 
                            variant="standard" 
                            value={dataDescription} 
                            onChange={e => setDataDescription(e.target.value)} 
                            disabled={disableUpdate}
                        />
                    </div>
                </Box>
                <div style={{float:"right", marginTop:'25px'}}>                                                                                                       
                    <Button variant="contained" onClick={handleClickEnviar} disabled={disableUpdate}>Enviar</Button>                    
                </div>                                            
            </div>
        </div>
        </div>
    );
}