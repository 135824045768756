import React, {useEffect, useState} from 'react'

//material
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import partnerAPI from '../../../Services/partnerAPI';
import { TextareaAutosize } from '@mui/material';

export default function ContratoInteligente(props){

    //#region Data
    const [formatContract, setFormatContract] = useState('')   
    const [contract, setContract] = useState('')     

    useEffect(() => {
        const fetchData = async () => {
            var contract = sessionStorage.getItem('contract');              
            setContract(contract);
        };
        fetchData();
      }, []);

    //#endregion   
    
    const handleClickEnviar = () => {
                
        if(formatContract === null || formatContract === ""){
            return window.alert('O campo Nome não pode estar vazio');
        }
                
        if(contract === null || contract === ""){
            return window.alert('Acesso não permitido para fazer determinada ação.');
        }

        var data = {
            'formatContract': formatContract,
            'ContractHash': contract
        };
        
        // agora fica para fazer a requisição para o back-end      
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };  

        partnerAPI.post("Partner/ContratoInteligente_Update", data,config)
        .then((res) => {            

            if(res.data.success === false){
                let text = "";
                for(let prop in res.data.erros){
                    text += `${prop} : ${res.data.erros[prop]}` + "\n";
                }                
                window.alert(text);                                                 
            }else{
                window.alert('Fluxo processado com sucesso!');                                
            }
        })            
        .catch(error => {
            window.alert('Ocorreu algum erro no processamento!');                
            console.log(error.response, 'ERRO');
        });

      };

    return (
        <div style={{margin:'30px'}}>
        <div className='container card'>        
            
            <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                <h5>Contrato Inteligente</h5>
            </div>

            <div className='container' style={{marginBottom: 10}}>               
                <Box component="form"  noValidate autoComplete="off"  >

                    <div style={{marginBottom: 10}}>
                        <TextareaAutosize className='col-sm-12'
                            label="Formato" 
                            variant="standard" 
                            value={formatContract} 
                            minRows={20}
                            onChange={e => setFormatContract(e.target.value)}                             
                        />
                    </div>                    
                </Box>
                <div style={{float:"right", marginTop:'25px'}}>                                                                                                       
                    <Button variant="contained" onClick={handleClickEnviar}>Enviar</Button>                    
                </div>                                            
            </div>
        </div>
        </div>
    );
}