import React, { useState, useEffect } from "react";

//material
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {  toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import partnerAPI from "../../../Services/partnerAPI";

export default function CashBack() {
    useEffect(()=>{
    contratoInteligente();
    loadNomeLoja();
  },[] );
  const [tipoVenda, setTipoVenda] = useState('');
  const [asset , setAsset] = useState();
  const [disableBtn , setDisableBtn] = useState(false);
  const [nomeTextfield , setNomeTextfield] = useState("Quantidade");
  const [txtTelefone , setTxtTelefone] = useState("");
  const [txtValorCompra , setTxtValorCompra] = useState("");
  const [dadosCashBack, setDadosCashBack] = useState({
    cryptoUserId:"" ,
    nmClient: "",
    phoneClient: "",
    cpfClient: "",
    emailClient: "",
    senderTokenQuantity: "",
    sellOfValue: "",
    isPorcentage: "",//FLAG
    storeName: "",
  });

  const contratoInteligente = async () =>{
    const contract = sessionStorage.getItem('contract');
    const uid = sessionStorage.getItem('uid');
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };
    await partnerAPI.get(`Partner/DadosToken_GetData?contract=${contract}&uid=${uid}`,{

    },config).then(
      result =>{
        const dataReturn = JSON.parse(result.data.data)
        setAsset(dataReturn.CdAsset)

      }
    ).catch(error =>{
      console.log(error.response, 'deu erro')
    })

  }

  const loadNomeLoja = () => {
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };   

    partnerAPI.post("/Partner/CashBack/GetConfiguration",{
      cryptoUserID: window.sessionStorage.getItem("uid")
    },config)
    .then((result)=>{
      const json = JSON.parse(result.data.content);

      if(json.CdAsset === 0){
        setDadosCashBack({storeName: ""})
      }else{
        setDadosCashBack({storeName: json.NmStore})
      }
    }).catch((error)=>{
      console.log(error, "handleEnviar error")
    })
  }

  const formatCurrency = (value) => {
    // Remove caracteres não numéricos da string
    const numericValue = value.replace(/\D/g, '');

    // Adiciona o símbolo R$ e formata a string com a máscara desejada
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
        if (i === numericValue.length - 2) {
            formattedValue += '.';
        }
        formattedValue += numericValue[i];
    }

    // Formata o número como moeda brasileira
    return Number(formattedValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

  const handleTelefoneChange = (e) => {
    // Aplicação manual da máscara de telefone
    const rawValue = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos

    const maskedValue = `(${rawValue.slice(0, 2)}) ${rawValue.slice(2, 7)}-${rawValue.slice(7, 11)}`;
    console.log(maskedValue, ' maskedValue handleTelefoneChange');
    return maskedValue;
  };

  const handleCpfChange = (cpf) => {
    cpf = cpf.replace(/\D/g, ''); // Remove tudo que não é número
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o ponto entre o terceiro e o quarto dígito
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o ponto entre o sétimo e o oitavo dígito
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca o hífen entre o décimo e o décimo primeiro dígito
    return cpf;
  };

  const handleAlterar = (e) => { 
    //debugger;
    let { name, value } = e.target;

    if(name === "sellOfValue"){
      let valorFormat = formatCurrency(value)

      if(valorFormat === "R$ 0,00"){

      }
      
      setTxtValorCompra(valorFormat);
      value = valorFormat;
    }

    if(name === "phoneClient"){
      let numberFormart = handleTelefoneChange(e);
      setTxtTelefone(numberFormart);
      value = numberFormart
    }

    if(name === "cpfClient"){
      if(e.target.value.length <= 14){
        value = handleCpfChange(e.target.value);
      }else{
        return;
      }
    }
    
    setDadosCashBack({
      ...dadosCashBack,
      [name]: value,
    });
  };
  
  const handleChange = (e) => {
    //debugger;
    setTipoVenda(e.target.value);
    
    
    if(e.target.value === true){
      setNomeTextfield("%")
    }else if (e.target.value === false){
      setNomeTextfield("Quantidade")
    }

    setDadosCashBack({
      ...dadosCashBack,
      tipoVenda : e.target.value,        
    })
  };

  const handleEnviar = async (e) => {
    //;
    e.preventDefault();

    if( dadosCashBack.nmClient === undefined ||
        dadosCashBack.phoneClient === undefined ||
        dadosCashBack.cpfClient === undefined ||
        dadosCashBack.emailClient === undefined ||
        dadosCashBack.senderTokenQuantity === undefined || 
        dadosCashBack.sellOfValue === undefined ||
        dadosCashBack.tipoVenda === undefined ||
        dadosCashBack.storeName === undefined){
          toast.warning("Preencha todos os campos !")
          return;
    }

    const jsonData = {      
      cryptoUserId: sessionStorage.getItem("uid"),
      nmClient: dadosCashBack.nmClient,
      phoneClient:dadosCashBack.phoneClient,
      cpfClient: dadosCashBack.cpfClient,
      emailClient: dadosCashBack.emailClient,
      senderTokenQuantity: dadosCashBack.senderTokenQuantity,
      sellOfValue: dadosCashBack.sellOfValue,
      isPorcentage:  dadosCashBack.tipoVenda,//FLAG
      storeName: dadosCashBack.storeName,
    }; 

    console.log(jsonData)

    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };

    let id = toast.loading('Processando...');

    setDisableBtn(true);

    const cashBack = await  partnerAPI.post("/Partner/AddCashBack",jsonData,config).then((result)=>{
      //console.log(result,"resultCashBack")

      //debugger;
      
      if(result.data.content !== null){

        let json;
        try{
          json = JSON.parse(result.data.content)
        }catch(error){
          json = result.data.content;
        }

        if(json.personalizedMessage === "Wallet sem fundos de Cashback! Entrar em contato com administrador do contrato." ||
            json.personalizedMessage ==="Quantitdade de token não pode ser menor que a porcentagem"){
          toast.update(id, {
            render: json.personalizedMessage, 
            type: "error", 
            isLoading: false, 
            autoClose: true
          });
          setDisableBtn(false);
          return;
        }

        if(json.cdSalesCashBack > 0){
          toast.update(id, {
            render: "Cliente Fidelizado com sucesso", 
            type: "success", 
            isLoading: false, 
            autoClose: true
          });

          setDadosCashBack({
            cryptoUserId:"" ,
            nmClient: "",
            phoneClient: "",
            cpfClient: "",
            emailClient: "",
            senderTokenQuantity: "",
            sellOfValue: "",
            isPorcentage: "",//FLAG
          });
          setTxtTelefone("");
          setTxtValorCompra("");
          setTipoVenda("");
          setDisableBtn(false);
          loadNomeLoja();
          return;
        }


        if(json.errors !== null){
          toast.update(id, {
            render: "Falha no Processamento", 
            type: "info", 
            isLoading: false, 
            autoClose: true
          });

          if(json.errors.EmailClient.length > 0){
            toast.warning(json.errors.EmailClient[0])
          }
  
          if(json.errors.CpfClient.length > 0){
            toast.warning(json.errors.CpfClient[0])
          }
          
          if(json.errors.PhoneClient.length > 0){
            toast.warning(json.errors.PhoneClient[0])
          }
        }

        setDisableBtn(false);

      }
  
    }).catch((error)=>{
      console.log(error, "handleEnviar error")
    })



  };

  return (
    <div className="container my-5 ">
      <div className="text-center  ">
        <h5>CashBack</h5>
      </div>

      <div className="container d-flex justify-content-center align-items-center col-sm-12 ">
        <Card className="text-center col-sm-4  ">
          <CardContent>
            <h6>Cadastre e fidelize seu cliente </h6>
            <div className="col-sm-12   ">
              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-textarea"
                  label="Nome da Loja"
                  placeholder=" Nome da Loja"
                  variant="standard"
                  type="text"
                  name="storeName"
                  value={dadosCashBack.storeName}
                  onChange={(e)=> handleAlterar(e)}
                  required
                />
              </div>

              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-textarea"
                  label="Nome Completo"
                  placeholder="Nome Completo"
                  variant="standard"
                  type="text"
                  name="nmClient"
                  value={dadosCashBack.nmClient}
                  onChange={(e)=> handleAlterar(e)}
                  required
                />
              </div>
              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-textarea"
                  label="Telefone"
                  placeholder="Telefone"
                  variant="standard"
                  type="number "
                  name="phoneClient"
                  value={txtTelefone}
                  onChange={(e)=> handleAlterar(e)}
                  required
                />
              </div>
              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-number"
                  label="CPF"
                  placeholder="CPF"
                  variant="standard"
                  name="cpfClient"
                  type="text"
                  value={dadosCashBack.cpfClient}
                  onChange={(e)=> handleAlterar(e)}
                  required
                />
              </div>
              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-textarea"
                  label="E-mail"
                  placeholder=" E-mail"
                  variant="standard"
                  type="email"
                  name="emailClient"
                  value={dadosCashBack.emailClient}
                  onChange={(e)=> handleAlterar(e)}
                  required
                />
              </div>
              <div className="mt-4">
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label">
                    Tipo de Venda
                  </InputLabel>
                  <Select
                    className="col-sm-12 align-text-left"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tipoVenda}
                    label="TipoVenda"
                    onChange={(e)=> handleChange(e)}>
                    
                    <MenuItem value={true}>Porcentagem</MenuItem>
                    <MenuItem value={false}>Quantidade</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-textarea"
                  label={nomeTextfield}
                  variant="standard"
                  type="number"
                  name="senderTokenQuantity"
                  value={dadosCashBack.senderTokenQuantity}
                  onChange={(e)=> handleAlterar(e)}
                />
              </div>

              <div className="mt-3">
                <TextField
                  className="col-sm-12"
                  id="standard-textarea"
                  label="Valor da Venda "
                  placeholder="Valor da Venda"
                  variant="standard"
                  type="text"
                  name="sellOfValue"
                  value={txtValorCompra}
                  onChange={(e)=> handleAlterar(e)}
                />
              </div>

              <div className="mt-5 ">
                <Stack direction="row-reverse ">
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={disableBtn}
                    onClick={handleEnviar}
                  >
                    <b>Cadastrar</b>
                  </Button>
                </Stack>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <ToastContainer />
    </div>
  );
}
