import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import QRCode from "react-qr-code";
import pixApi from "../../../../Services/pixApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  display: "flex",
  justifyContent: "center",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const steps = ["Selecionar a moeda e o valor", "Pix QR code"];

export default function ModalDeposito({ open, handleClose }) {
  const [valuePix, setValuePix] = useState();
  const [valueCPFCNPJ, setValueCPFCNPJ] = useState("");
  const [pixCopiaCola, setPixCopiaCola] = useState("");
  const [nomeOuEmpresa, setNomeOuEmpresa] = useState ('');
  const [btnCopiarPix, setBtnCopiarPix] = useState ('Copiar');

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isCheckedAutCIPay, setIsCheckedAutCIPay] = useState(true);

  const verificandoPix = async (txId) => {
    let refreshId = setInterval(async function () {
      console.log("Verificando Pix");
      const result = await pixApi.post("/Payment/Get/Collection/Pix", {
        txtID: txId,
      });
      console.log(result, "Resultado");

      if (
        result.data.content &&
        result.data.content.pix &&
        result.data.content.pix.length > 0
      ) {
        clearInterval(refreshId);
        handleNext();
      }
    }, 2000);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixCopiaCola).then(() => {
      setBtnCopiarPix("Copiado ...")

      setTimeout(() => {
        setBtnCopiarPix("Copiar")
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy text to clipboard:', err);
    });

    
  };

  const handleNext = async () => {

    if (activeStep === 0) {
      handleCreatePix();
    }


  };

  const handleCreatePix = async () => {
    //debugger;
    let newSkipped = skipped;

    console.log(valuePix, "valuePix");
    let validateCPFCNPJ = false;
    let payload ={};

    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    if(valuePix === "R$&nbsp;0,00" || valuePix === undefined){
      toast.warning("Insira Valor do Depósito.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(nomeOuEmpresa.length === 0){
      toast.warning("Insira nome ou empresa para compra.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(valueCPFCNPJ.length < 14){
      toast.warning("Insira CPF ou CNPJ Valido.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    payload.cryptoUserID = sessionStorage.getItem("uid");
    payload.cpF_CNPJ = valueCPFCNPJ;
    payload.completedName = nomeOuEmpresa;
    payload.amount = formatToAmericanCurrency(valuePix);
    payload.descriptionPix = "Pix deposito"

    if(valueCPFCNPJ.length === 14){
      payload.isCPF = true
      
      validateCPFCNPJ = validateCPF(valueCPFCNPJ);
    }

    if(valueCPFCNPJ.length === 18){
      payload.isCNPJ = true
      
      validateCPFCNPJ = validateCNPJ(valueCPFCNPJ);
    }

    if(isCheckedAutCIPay){
      payload.isAutomaticConvertCipayToken = true;
    }


    console.log(payload, "payload");
    console.log(validateCPFCNPJ, "validateCPFCNPJ");


    if(validateCPFCNPJ=== true){
      const result = await pixApi.post("Payment/CreatePix", payload, config);
      //console.log(result, "result Pix")

      if(result.status === 200){
        setPixCopiaCola(result.data.result.content.pixCopiaECola)

        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }else{
      toast.warning("Insira CPF ou CNPJ Valido.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    
    return;
  };

  const formatToAmericanCurrency = (amount) =>{
    // Remove "R$" e espaços, remove os pontos e converte vírgula para ponto
    let numericValue = amount.replace(/[^\d,]/g, '').replace('.', '').replace(',', '.');
    return parseFloat(numericValue).toFixed(2); // Formata para duas casas decimais
  }

  const formatCurrency = (value) => {
    // Remove caracteres não numéricos da string
    const numericValue = value.replace(/\D/g, '');

    // Adiciona o símbolo R$ e formata a string com a máscara desejada
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
        if (i === numericValue.length - 2) {
            formattedValue += '.';
        }
        formattedValue += numericValue[i];
    }

    // Formata o número como moeda brasileira
    return Number(formattedValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  const validateCPF = (cpf) =>{
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11) return false;
    if (/^(\d)\1{10}$/.test(cpf)) return false;

    let sum = 0;
    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    let remainder = (sum * 10) % 11;
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    if (remainder !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  }

  const validateCNPJ= (cnpj) =>{
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;
    if (/^(\d)\1{13}$/.test(cnpj)) return false;

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += numbers.charAt(length - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== parseInt(digits.charAt(0))) return false;

    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += numbers.charAt(length - i) * pos--;
        if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  }

  const handleCheckboxChange = (event) => {
    setIsCheckedAutCIPay(event.target.checked);
  };

  const handleCpfChange = (cpf) => {
    cpf = cpf.replace(/\D/g, ''); // Remove tudo que não é número
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o ponto entre o terceiro e o quarto dígito
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o ponto entre o sétimo e o oitavo dígito
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca o hífen entre o décimo e o décimo primeiro dígito
    return cpf;
  };

  const handleCNPJChange = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, ''); // Remove tudo que não é número
    cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2'); // Coloca o ponto entre o segundo e o terceiro dígito
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o ponto entre o quinto e o sexto dígito
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2'); // Coloca a barra entre o oitavo e o nono dígito
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2'); // Coloca o hífen entre o décimo terceiro e o décimo quarto dígito
    return cnpj;
  };

  const handleInputChange = (event) => {
    const novoValor = formatCurrency(event.target.value.replace(/[^0-9.,]/g, ''));
    setValuePix(novoValor);
  };

  const handleInputCPFCNPJ = (event) => {
    let novoValor = event.target.value.replace(/\D/g, ''); // Remove tudo que não é número
    if (novoValor.length <= 11) {
      novoValor = handleCpfChange(novoValor);
    } else {
      novoValor = handleCNPJChange(novoValor);
    }
    setValueCPFCNPJ(novoValor);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style}>
        <Box sx={{ width: "100%" }}>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <IconButton onClick={() => handleClose()}>X</IconButton>
          </Box>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};

              stepProps.completed = false;
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <Box
              my={5}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <CheckCircleIcon color="success" sx={{ fontSize: "200px" }} />
              <Typography variant="h5">
                Deposito realizado com sucesso.
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {activeStep === 0 && (
            
            <Box className="container col-sm-10" width="70%" display="flex" flexDirection="column" gap="10px">
              <div style={{margin: 5}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  1. Selecione a moeda
                </Typography>
              </div>
              <InputLabel
                sx={{ fontWeight: 600, }}
                id="demo-simple-select-label"
              >
                Moeda
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={1}
                label="Age"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#9C27B0', // cor da borda
                    },
                    '&:hover fieldset': {
                      borderColor: '#9C27B0', // cor da borda ao focar
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#9C27B0', // cor da borda quando ativo
                    },
                  },
                  '& .MuiSelect-icon': {
                    color: '#9C27B0', // cor do ícone de dropdown
                  },
                }}
              >
                <MenuItem value={1}>BRL - Real Brasileiro</MenuItem>
              </Select>
              <Box>
                <Typography>Depositar</Typography>
                <FormControlLabel
                  checked
                  control={<Checkbox />}
                  label="Pix"
                />

              </Box>
              <TextField
                label="Digite o valor em real"
                value={valuePix}
                onChange={(e) => handleInputChange(e)}
              />

              <TextField
                label="Nome completo ou Razão social"
                value={nomeOuEmpresa}
                onChange={(e)=> setNomeOuEmpresa(e.target.value)}
 
              />

              <TextField
                label="Digite o CPF / CNPJ"
                value={valueCPFCNPJ}
                onChange={handleInputCPFCNPJ}
                InputProps={{
                  inputProps: {
                    maxLength: 18, // Máximo de caracteres para CNPJ com a máscara
                  },
                }}
              /> 
                <FormControlLabel
                  control={<Checkbox checked={isCheckedAutCIPay} onChange={handleCheckboxChange} />}
                  label="Converter automaticamente para CIPay"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Eu li e concordo com os Termos e Condições para 
                  utilização da funcionalidade de Trading Fiduciário."
                />
                
            </Box>
            
          )}
          {activeStep === 1 && (
            <Box
              sx={{
                height: "auto",
                margin: "0 auto",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {pixCopiaCola != "" ? (
                <>
                  <Typography my="30px" fontWeight="600">
                    Leia o QR Code ou copie e cole a frase abaixo
                  </Typography>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "250px", width: "100%" }}
                    value={pixCopiaCola}
                    viewBox={`0 0 256 256`}
                  />
                  
                  <div className="my-3 col-sm-12">
                    <TextField
                      className="col-sm-12"
                      label="Pix copia e cola"
                      value={pixCopiaCola}
                      multiline
                      rows={4}
                      InputProps={{
                        style: {
                          textAlign: 'center',
                        }
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Cor da borda padrão
                          },
                          '&:hover fieldset': {
                            borderColor: 'green', // Cor da borda ao passar o mouse
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'green', // Cor da borda ao focar no campo
                          },
                        },
                      }}
                    />
                    <div className="container col-sm-7 my-3">
                      <Button
                        variant="contained"
                        color="secondary"
                        className="col-sm-12"
                        onClick={() => {
                          copyToClipboard()
                        }}
                      >
                        {btnCopiarPix}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={260}
                >
                  <CircularProgress size={60} color="secondary" />
                </Box>
              )}
            </Box>
          )}
          <React.Fragment>
            <div className="container col-sm-9">
              <div className="row">

                <div className="col-sm-8"></div>
                <div className="col-sm-4">
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, marginBottom: 2}}>
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          activeStep === steps.length ? handleClose() : handleNext();
                        }}
                      >
                        {activeStep === steps.length ? "Fechar" : "Próximo"}
                      </Button>
                    )}
                  </Box>
                </div>

              </div>
            </div>

          </React.Fragment>
        </Box>
      </Box>
    </Modal>
  );
}