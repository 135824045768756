import React, { useState, useContext, useEffect } from "react";
import authContext from "../../../Context/authContext";
import bankAPI from "../../../Services/bankAPI";
import { format } from 'date-fns';

//material
import { FormControl, Select, MenuItem, InputLabel, Link } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircularProgress from "@mui/material/CircularProgress";

//components
import "bootstrap/dist/css/bootstrap.css";
import { BiLineChart, BiLineChartDown } from "react-icons/bi";
import dayjs from "dayjs";

const columns = [
     { id: 'tipo', label: 'Tipo', minWidth: 100 },
     { id: 'cripto', label: 'Cripto', minWidth: 100 },
     { id: 'data', label: 'Data', minWidth: 100 },
     { id: 'valor', label: 'Valor', minWidth: 100 },
     { id: 'status', label: 'Status', minWidth: 100 },
];

export default function Historico() {

     const { authenticated, setAuthenticated } = useContext(authContext);
     const [visibility, setVisibility] = useState(false);
     const [listCryptoInWallet, setListCryptoInWallet] = useState({
          cryptoUserID: 0,
          walletCryptosPessoa: []
     });

     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const [historicoSque, setHistoricoSaque] = useState({
          saque: [],
     });
     const [texEndereco, setTexEndereco] = useState('');

     const [startDate, setStartDate] = useState(null);
     const [endDate, setEndDate] = useState(null);
     const [intervaloDias, setIntervaloDias] = useState(30);
     const [statusHandleDias, setstatusHandleDias] = useState(true);
     const [loading, setLoading] = useState(false);

     useEffect(() => {
          loadCryptosInWallet();
          buscarMeuEndereco();

          if(statusHandleDias){
               defaultDay(intervaloDias);
          }else{
               
               const interval = setInterval(() => {
                    historicoSaque(startDate, endDate);
               }, 6000)
               return () => clearInterval(interval);
          }
     }, [startDate, endDate, statusHandleDias]);

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     const handleVisibility = () => {
          setVisibility(!visibility);
     };

     const handleDaysChange = (event) => {
          setstatusHandleDias(false);
          if (event.target.value > 0) {
               setIntervaloDias(event.target.value);
               setStartDate(dayjs().subtract(event.target.value, 'day'));
               setEndDate(dayjs());
               historicoSaque(dayjs().subtract(event.target.value, 'day'), dayjs())
          }
     };

     const defaultDay = (days) => {
          historicoSaque(dayjs().subtract(days, 'day'), dayjs())
     }

     const findCryptoImage = (cryptoId) => {
          if (listCryptoInWallet &&
               listCryptoInWallet.walletCryptosPessoa &&
               listCryptoInWallet.walletCryptosPessoa.length > 0) {

               const crypto = listCryptoInWallet.walletCryptosPessoa.find(cr => cr.cdCrypto === cryptoId);

               if (crypto && crypto.cdCrypto) {
                    return <img height={'20px'} width={'20px'} src={crypto.urlImgCrypto} alt={crypto.eliasCrypto} />;
               }
          }

          return <img height={'20px'} width={'20px'} src={''} alt={''} />;
     }


     const historicoSaque = async (start, end) => {
          setLoading(true);
          const config = {
               headers: {
                    Authorization: "Bearer " + (await sessionStorage.getItem("token")),
               },
          };

          bankAPI.post("/Ordem/HistoricoSaque", {
               cryptoUserID: sessionStorage.getItem("uid"),
               hashRemetente: "",
               hashDestinatario: "",
               cdCripto: 0,
               cdAsset: 0,
               proximaPagina: 0,
               totalDados: 0,
               dataInicial: start ? start.toISOString() : null,
               dataFinal: end ? end.toISOString() : null
          }, config).then((result) => {

               //console.log(result.data, '/Ordem/HistoricoSaque');
               setHistoricoSaque(result.data);
          }
          ).catch((error) => {
               // console.log(error.response, '/Ordem/HistoricoSaque');
               if (error.response.status === 401) {
                    setAuthenticated(false);
               }
          }).finally(() => {
               setLoading(false);
          });
     }

     const buscarMeuEndereco = async () => {

          const config = {
               headers: {
                    Authorization: "Bearer " + (await sessionStorage.getItem("token")),
               },
          };

          bankAPI.post("/Wallet/meuEndereco", {
               cryptoUserID: sessionStorage.getItem("uid")
          }, config).then((result) => {

               setTexEndereco(result.data.hashWallet);
               //console.log(result.data, 'buscarMeuEndereco');
          }
          ).catch((error) => {
               // console.log(error.response, 'buscarMeuEndereco');
               if (error.response.status === 401) {
                    setAuthenticated(false);
               }
          })
     }

     const loadCryptosInWallet = async () => {

          const config = {
               headers: {
                    Authorization: "Bearer " + window.sessionStorage.getItem("token"),
               },
          };

          await bankAPI
               .post(
                    "/Wallet/walletPessoa",
                    {
                         cryptoUserID: window.sessionStorage.getItem("uid"),
                    },
                    config
               )
               .then((result) => {
                    setListCryptoInWallet(result.data);
                    //console.log(result.data);
               })
               .catch((error) => {
                    //console.log(error.response, "walletPessoa WalletPessoaList");
               });
     };

     return (
          <div className="mb-6 pb-4" style={{ marginTop: 15 }}>
               <Card className="container   align-items-center col-sm-8 mt-6 mb-5 pb-5  ">
                    <CardContent className="mb-5 ">
                         <div
                              style={{
                                   display: "flex",
                                   alignItems: "center",
                                   padding: "5px 10px",
                                   marginBottom: 20
                              }}
                         >
                              <p className="display-6" style={{ margin: 0, width: "30%" }}>
                                   Histórico
                              </p>
                              <div style={{ marginLeft: 10, marginTop: 15 }}>
                                   <FormControl>
                                        <InputLabel id="intervalo-label" >Intervalo de Dias</InputLabel>
                                        <Select
                                             labelId="intervalo-label"
                                             id="intervalo"
                                             value={intervaloDias}
                                             onChange={handleDaysChange}
                                        >
                                             <MenuItem value={30}>até 30 dias</MenuItem>
                                             <MenuItem value={60}>até 60 dias</MenuItem>
                                             <MenuItem value={90}>até 90 dias</MenuItem>
                                             {/*<MenuItem value={-1}>
                                                       <Link to={`https://explorer.cipay.com.br/#/`}>
                                                            Mais de 90 dias
                                                       </Link>
                                                  </MenuItem>*/}
                                        </Select>
                                   </FormControl>
                              </div>
                              <div style={{ marginLeft: "25px" }}>
                                   {loading ? <CircularProgress color="secondary" /> : null}
                              </div>
                         </div>
                         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                              <TableContainer sx={{ maxHeight: 440 }}>
                                   <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                             <TableRow>
                                                  {columns.map((column) => (
                                                       <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth, textAlign: 'center' }}>
                                                            {column.label}
                                                       </TableCell>
                                                  ))}
                                             </TableRow>
                                        </TableHead>
                                        <TableBody>
                                             {historicoSque.saque
                                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                  .map((row, index) => (
                                                       <TableRow key={index}>
                                                            <TableCell align="center">
                                                                 {row.hashSender === texEndereco ? (
                                                                      <BiLineChartDown style={{ fontSize: 25, color: row.hashSender !== texEndereco ? '#8ade82' : 'inherit', margin: 5 }} />
                                                                 ) : (
                                                                      <BiLineChart style={{ fontSize: 25, color: row.hashSender !== texEndereco ? '#8ade82' : 'inherit', margin: 5 }} />
                                                                 )}
                                                                 {row.hashSender === texEndereco ? 'Enviado' : 'Recebido'}
                                                            </TableCell>
                                                            <TableCell
                                                                 align="center">
                                                                 {findCryptoImage(row.cdCripto)}
                                                            </TableCell>
                                                            <TableCell align="center">{format(new Date(row.insertDate), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                            <TableCell align="center"
                                                                 style={{ color: row.hashSender !== texEndereco ? '#8ade82' : 'inherit' }}>
                                                                 {Number(row.orderValue).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                 {row.validationDate === "0001-01-01T00:00:00Z" ? (
                                                                      <span>processando</span>
                                                                 ) : (
                                                                      <span>processado</span>
                                                                 )}
                                                            </TableCell>
                                                       </TableRow>
                                                  ))}
                                        </TableBody>
                                   </Table>
                              </TableContainer>
                              <TablePagination
                                   rowsPerPageOptions={[10, 25, 100]}
                                   component="div"
                                   count={historicoSque.saque.length}
                                   rowsPerPage={rowsPerPage}
                                   page={page}
                                   onPageChange={(event, newPage) => setPage(newPage)}
                                   onRowsPerPageChange={(event) => {
                                        setRowsPerPage(parseInt(event.target.value, 10));
                                        setPage(0);
                                   }}
                              />
                         </Paper>
                    </CardContent>
               </Card>
          </div>
     )
}