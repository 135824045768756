import React, {useState, useEffect, useContext} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import bankAPI from '../../../Services/bankAPI';
import authContext from '../../../Context/authContext';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

export default function Deposito(){
  const [coin, setCoin] = useState('');
  const [coinState] = useState({
    value: 0
  });
  const [openCoin, setOpenCoin] = useState(false);
  const [criptomoeda, setCriptomoeda] = useState('Criptomoeda');

  const [rede, setRede] = useState('');
  const [openRede, setOpenRede] = useState(false);

  const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(0);
  const [dynamicPaddingBottomTwo, setDynamicPaddingBottomTwo] = useState(180);
  const [dynamicMarginBottom, setDynamicMarginBottom] = useState(170);
  const [depositoSelect, setDepositoSelect] = useState(false);
  const  {authenticated, setAuthenticated} = useContext(authContext);
  
  const[texEnderecoNode, setTexEnderecoNode]= useState('');
  const[visibleContainer, setVisibleContainer ]= useState(false);

  useEffect(() => {
    marginbottomdynamic();
    loadCryptos();

  }, []);

  const [listCrypto, setlistCrypto] = useState({
    cdPessoa: 0,
    walletCryptosPessoa:[]
  });

  const [listRede, setlistRede] = useState({
    rede:[]
  });
  //const [redeSelecionada, setRedeSelecionada] = useState('');

  const[meuEndereco ,setMeuEndereco]= useState({
    cryptoUserID: 0,
    hashWallet: ""
  });

  const handleChangeCoin = event => {
    //;
    if(event.target.value > 0){
      coinState.value = event.target.value;
      setDepositoSelect(true);
    }
 
    setCoin(event.target.value);
    
    setVisibleContainer(true);
    let crypto = listCrypto.walletCryptosPessoa.filter(x=> x.cdCrypto === event.target.value);

    if(isNaN(coin)){
      console.log('coin is not number')
    }else{
      loadRedesCrypto(crypto[0].eliasCrypto);
      //console.log(crypto[0].vlSaldo, 'isNaN'
    }

    loadRedesCrypto(crypto[0].eliasCrypto);
    setRede('');
    setTexEnderecoNode('');
    setDynamicMarginBottom(80);
    setDynamicPaddingBottomTwo(0);
  };

  const handleChangeRede = event => {
    
    setRede(event.target.value);
    let nomeRede = localizarRede(event.target.value);
    console.log(nomeRede, 'nomeRede')
    
    if(nomeRede === 'CIPY20'){
      buscarMeuEndereco();
      //console.log(texEndereco, 'texEndereco');
      return;
    }
    let tt = localizarCryptoNode(event.target.value);

    try{
      const node = JSON.parse(tt);
      selectHashRede(node.asset, node.nmRede);
    }catch(error){

    }
  };

  const handleCloseCoin = () => {
    console.log('entrou handleCloseCoin')
    setOpenCoin(false);
    
  };

  const handleCloseRede = () => {
    setOpenRede(false);
  };

  const handleOpenCoin = () => {
    setOpenCoin(true);
  };

  const handleOpenRede = () => {
    setOpenRede(true);

  };

  const marginbottomdynamic = () =>{

    if(window.screen.width === 1920 && window.screen.height === 1080){
        setDynamicPaddingBottom(91);
    }else{
      setDynamicPaddingBottom(47);
    }

  }

  const localizarCryptoNode = (nrRede) =>{
    
    switch (nrRede) {
      case 1:
        return '{ "asset": "CIPAY", "nmRede": "CIPY20" }';
      case 2:
        return '{ "asset": "BTC", "nmRede": "Bitcoin" }';
      case 3:
        return '{ "asset": "BNB", "smartContract": [""], "nmRede": "BEP2" }';
      case 4:
        return '{ "asset": "BNB", "smartContract": [""], "nmRede": "BEP20" }';
      case 5:
        return '{ "asset": "ETH", "smartContract": [""], "nmRede": "ERC20"  }';
      case 6:
        return 'ADA';
      case 7:
        return 'TRC20';
      default:
        // console.log(`Sorry, we are out of ${expr}.`);
    }
  }

  const loadCryptos = async () => {
      
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
      },
    };
    
    await bankAPI.post('/Partner/WalletPerson',{
      cryptoUserID: sessionStorage.getItem("uid")
    },config).then(
      result => {
        setlistCrypto(result.data);
        setDepositoSelect(true);
        console.log(result, 'loadCryptos');
      }
    ).catch(error=>{
      console.log(error.response, 'loadCryptos')
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });

  }

  const loadRedesCrypto = async (eliasCryptoValue) => {
    console.log(eliasCryptoValue, 'entrou loadRedesCrypto')
    //let eliasCrypto = localizarCrypto(cdCrypto);
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
      },
    };
    
    bankAPI.post('/Partner/Chain/Withdraw',{
      eliasCrypto: eliasCryptoValue
    },config).then(result => {
        setlistRede(result.data);
        console.log(result, 'loadRedes')
      }
    ).catch(error => {
      console.log(error.response, 'loadRedes')
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });

  }

  const selectHashRede = async (eliasCryptoValue, nomeRede) => {
    //let eliasCrypto = localizarCrypto(cdCrypto);
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
      },
    };
    
    await bankAPI.post('/Wallet/rede/deposito',{
      eliasCrypto: eliasCryptoValue,
      cryptoUserID: parseInt(sessionStorage.getItem("uid"))
    },config).then(result => {

        console.log(result.data, 'selectHashRede');

        setTexEnderecoNode(result.data.rede[0].hashRede);
        
      }
    ).catch(error => {
      console.log(error.response, 'selectHashRede')
      if(undefined !== undefined){        
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
      }
    });

  }

  const buscarMeuEndereco = async () =>{
    const config = {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
        },
  
    };

    bankAPI.post('/Partner/HashAddress', {
        cryptoUserID: sessionStorage.getItem("uid")
    },config).then(
        result => {
          setMeuEndereco(result.data);
          setTexEnderecoNode(result.data.hashWallet);
          console.log(result.data, 'buscarMeuEndereco');
        }
    ).catch(error =>{
        // console.log(error.response, 'buscarMeuEndereco');
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
    })
  }

  const localizarRede = (nmRede) =>{

    switch (nmRede) {
      case 1:
        return 'CIPY20';
      case 2:
        return 'Bitcoin';
      case 3:
        return 'BEP2';
      case 4:
        return 'BEP20';
      case 5:
        return 'ERC20';
      case 6:
        return 'ADA';
      case 7:
        return 'TRC20';
      default:
        // console.log(`Sorry, we are out of ${expr}.`);
    }
  }

    return (

        <div className='row col-sm-12' style={{padding: 10, paddingBottom: dynamicPaddingBottom}}>
          
          <Card className='col-sm-5' style={{marginLeft: 10, marginBottom: dynamicMarginBottom, paddingBottom: dynamicPaddingBottomTwo}}>
            <CardContent style={{padding: 20}}>
            <FormControl variant="standard" className="col-sm-12">
              <InputLabel id="coin-controller-label" value={coin} style={{ padding: 0 }}>
                Criptomoeda
              </InputLabel>

              <Select
                labelId="coin-controller-label"
                id="coin-controller-label"
                open={openCoin}
                onClose={handleCloseCoin}
                onOpen={handleOpenCoin}
                value={coin}
                label="Coin"
                onChange={(e) => handleChangeCoin(e)}
                onBlur={handleChangeCoin}
              >
                {!depositoSelect ? (
                  <div style={{ padding: 5 }}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  listCrypto.walletCryptosPessoa.map((crypto, index) => (


                    <MenuItem key={index} value={crypto.cdCrypto} className="  "  >
                      <div className="  d-flex align-items-center ">
                        <Avatar alt="Remy Sharp" className="me-2 " style={{ width: 30, height: 30 }} src={crypto.urlImgCrypto} key={index} value={crypto.cdCrypto} />

                        <div style={{ marginLeft: 5 }} className="col-sm-6">
                          {crypto.eliasCrypto}
                        </div>
                      </div>
                    </MenuItem>

                  ))
                )}
              </Select>
            </FormControl>

              {
                visibleContainer &&
                <div>
                  <FormControl className='col-sm-12 my-3' variant="standard" >
                  <InputLabel id="rede-controller-label">Rede</InputLabel>
                  
                    <Select
                      labelId="rede-controller-label"
                      id="rede-controller-label"
                      open={openRede}
                      onClose={handleCloseRede}
                      onOpen={handleOpenRede}
                      value={rede}
                      label="Rede"
                      onChange={handleChangeRede}
                    >
                      
                      {
                        listRede.rede.map((rede, index) =>
                          <MenuItem key={index} value={rede.nrRede}>{rede.nmRede}</MenuItem>
                        )
                      }

                    </Select>
                  </FormControl>
                  
                  <div style={{marginTop: 10,}}>
                    <TextField 
                      inputProps={{readOnly: true}} 
                      className='col-sm-12' 
                      id="outlined-basic" 
                      value={texEnderecoNode} 
                      onChange={e => setTexEnderecoNode(e.target.value)} 
                      label="Hash Endereço" 
                      variant="standard" 
                    />
                  </div>

                  <div style={{marginTop: 40,}}>
                      <ul>
                          <li>Envie apenas {criptomoeda} para este endereço de depósito.</li>
                          <li>Certifique-se de que a rede seja {criptomoeda}.</li>
                          <li>Não envie NFTs para este endereço. Saiba como depositar NFTs</li>
                      </ul>
                  </div>
                  
                  {/* <div style={{marginTop: 63, paddingBottom: 10}}>
                    <Button className='col-md-12' variant="outlined" style={{backgroundColor: '#D4AA51', color: 'black', borderBlockColor: 'aliceblue'}}>Transferir</Button>
                  </div> */}

                </div>
              }
            </CardContent>
            
          </Card>

          <div className='col-sm-4'>
            <p className='display-6' style={{padding: 10}}>Deposito BlockChain</p>
            <div className='text-left' style={{padding: 10}}>
              <p>Certifique que endereço esteja correto. Caso esteja Incorreto Ocorrerá perda de ativos</p>
            </div>
          </div>

        </div>


    )
        
}