import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, BrowserRouter as Router } from "react-router-dom";

//import Card from '@mui/material/Card';
//import CardContent from '@mui/material/CardContent';
import TextField from "@mui/material/TextField";

import authContext from "../../../Context/authContext";

//material  
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CardActionArea from "@mui/material/CardActionArea";
import Avatar from '@mui/material/Avatar';

//components
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Divider } from "@mui/material";
import partnerAPI from "../../../Services/partnerAPI";

export default function Saque() {
  const navigate = useNavigate();

  const [coin, setCoin] = useState("");
  const [coinFee, setCoinFee] = useState("");

  const [openCoin, setOpenCoin] = useState(false);
  const [openCoinFee, setOpenCoinFee] = useState(false);
  const [avatarShow, setAvatarShow] = useState(true);

  const state = useLocation();
  const [coinSaldo, setCoinSaldo] = useState();

  const [rede, setRede] = useState("");
  const [openRede, setOpenRede] = useState(false);
  const [listRede, setlistRede] = useState({
    rede: [],
  });

  const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(90);
  const [saqueSelect, setSaqueSelect] = useState(false);
  const [redeSelect, setRedeSelect] = useState(false);
  //const [valorDisponivelCripto, setValorDisponivelCripto] = useState(0);
  const { authenticated, setAuthenticated } = useContext(authContext);

  const [loadingMsg, setLoadingMsg] = useState(false);
  const [transferenciaMsg, setTransferenciaMsg] = useState(false);
  const [colorDynamicButton, setColorDynamicButton] = useState("#D4AA51");
  const [nomeButton, setNomeButton] = useState("Transferir");
  const [transferir, setTransferir] = useState ('')
  const [imgConcluido , setImgConcluido]= useState(false)

  //sacarblockChain
  const [textQuantia, setTextQuantia] = useState(0);
  const [textRede, setTextRede] = useState("");
  const [texEndereco, setTexEndereco] = useState("");
  const [meuEndereco, setMeuEndereco] = useState({
    cryptoUserID: 0,
    hashWallet: "", 
  });

  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    marginbottomdynamic();
    verificarSaldo();
    loadCryptos();
    if (state.state !== null) {
      loadRedesCrypto(localizarCrypto(state.state.eliasCrypto));
    }
    buscarMeuEndereco();
  }, []);

  const handleChangeCoin = async (event) => {
    debugger;
    //console.log(event.target, 'event.target.value handleChangeCoin')
    setRede("");
    setCoinFee("");

    setAvatarShow(false);
    if (state.state !== null) {
      loadRedesCrypto(localizarCrypto(state.state.eliasCrypto));
    }

    setCoin(event.target.value);
    //console.log(event.target.value, " event target value");
    let crypto = listCrypto.walletCryptosPessoa.filter(
      (x) => x.cdCrypto === event.target.value
    );
    //console.log(crypto, 'listCrypto.walletCryptosPessoa.filter handleChangeCoin');

    let asset = await localizarCryptoPorNome(crypto[0].eliasCrypto);

    //console.log(asset, "asset handleChangeCoin");

    if (asset.conteudo.isSmartContract) {
      localizarSaldoCrypto(crypto[0].eliasCrypto);
    } else {
      localizarSaldoCrypto(event.target.value);
    }

    if (isNaN(coin)) {
      //console.log("coin is not number");
    } else {
      loadRedesCrypto(crypto[0].eliasCrypto);
      //console.log(crypto[0].vlSaldo, 'isNaN'
    }
  };

  const handleChangeCoinFee = async (event) => {
    setCoinFee(event.target.value);
  };

  const handleCloseCoinFee = () => {
    setOpenCoinFee(false);
  };

  const handleOpenCoinFee = () => {
    setOpenCoinFee(true);
  };

  const handleChangeRede = (event) => {
    debugger;
    setRede(event.target.value);

    const redeObject = listRede.rede.filter(
      (x) => x.nrRede === event.target.value
    );

    if(redeObject[0].nmRede === "CIPY20"){
      setCoinFee(1);
    }

    if(redeObject[0].nmRede === "ERC20"){
      setCoinFee(3);
    }

    if(redeObject[0].nmRede === "Bitcoin"){
      setCoinFee(2);
    }

    console.log(redeObject, "redeObject")
    //console.log(event.target.value, "handleChangeRede");
    //console.log(redeObject, "redeObject");
    setTextRede(redeObject[0].nmRede);
  };

  const handleCloseRede = () => {
    setOpenRede(false);
    //console.log(coin, 'handleCloseRede');
  };

  const handleOpenRede = () => {
    setOpenRede(true);
  };

  const handleCloseCoin = () => {
    setOpenCoin(false);
    //console.log(coin, 'handleChangeCoin');
    //loadRedesCrypto(localizarCrypto(coin));
  };

  const handleOpenCoin = () => {
    setOpenCoin(true);
    //console.log(coin, 'handleOpenCoin');
  };

  const marginbottomdynamic = () => {
    ////console.log(state.state.eliasCrypto, 'state uselocation')
    if (window.screen.width === 1920 && window.screen.height === 1080) {
      setDynamicPaddingBottom(78);
    } else {
      setDynamicPaddingBottom(77);
    }
  };

  const verificarSaldo = () => {
    console.log(state.state, "state");

    if (state.state != null) {
      setCoinSaldo(state.state.coins);
      setCoin(state.state.cdCrypto);
    }
  };

  const localizarCrypto = (eliasCrypto) => {
    switch (eliasCrypto) {
      case 1:
        return "GDPAYcoin";
      case 2:
        return "BTC";
      case 3:
        return "ETH";
      case 4:
        return "USDT";
      case 5:
        return "BNB";
      case 6:
        return "ADA";
      default:
        return eliasCrypto;
    }
  };

  const localizarTipoSaque = (tipoSaque) => {
    switch (tipoSaque) {
      case "BlockChain":
        return 1;
      case "ContaNacional":
        return 2;
      default:
      // console.log(`Sorry, we are out of ${expr}.`);
    }
  };

  const [listCrypto, setlistCrypto] = useState({
    cdPessoa: 0,
    walletCryptosPessoa: [],
  });

  const loadCryptos = async () => {
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    await partnerAPI.post("/Partner/WalletPerson",{
        cryptoUserID: sessionStorage.getItem("uid"),
      },config).then((result) => {
        setlistCrypto(result.data);
        setSaqueSelect(true);
      //console.log(result, "loadCryptos");
    })
    .catch((error) => {
      //console.log(error.response, "loadCryptos");
      if (error.response.status === 401) {
         setAuthenticated(false);
      }
    });
  };

  const loadRedesCrypto = async (eliasCryptoValue) => {
    //console.log(eliasCryptoValue, "entrou loadRedesCrypto");
    //let eliasCrypto = localizarCrypto(cdCrypto);
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    partnerAPI.post("/Partner/Chain/Withdraw",{
          eliasCrypto: eliasCryptoValue,
          cryptoUserID: sessionStorage.getItem("uid") 
    },config).then((result) => {
        setlistRede(result.data);
        setRedeSelect(true);
        console.log(result, "loadRedes");
    }).catch((error) => {
        console.log(error.response, "loadRedes");
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
    });
  };

  const localizarSaldoCrypto = async (cdCryptoValue) => {
    const config = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    };

    partnerAPI
    .post(
      "/Partner/BalanceCoinWallet",
      {
        cryptoUserID: sessionStorage.getItem("uid"),
        cdCrypto: String(cdCryptoValue),
      },
      config
      
    )
    .then((result) => {
      setCoinSaldo(result.data.vlSaldo);

      //console.log(result, "localizarSaldoCrypto");
    })
    .catch((error) => {
      //console.log(error.response, "localizarSaldoCrypto");
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });
  };

  const buscarMeuEndereco = async () => {
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    partnerAPI
      .post(
      "/Partner/HashAddress",
        {
          cryptoUserID: sessionStorage.getItem("uid"),
        },
        config
      )
      .then((result) => {
        setMeuEndereco(result.data);  
        // console.log(result.data, 'buscarMeuEndereco');
      })
      .catch((error) => {
        // console.log(error.response, 'buscarMeuEndereco');
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
      });
  };

  const localizarCryptoPorNome = async (eliasCrypto) => {
    //console.log(eliasCrypto, "entrou localizarCryptoPorNome");

    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    return partnerAPI
      .post(
        "/Partner/FindCrypto",
        {
          nmCrypto: eliasCrypto,
        },
        config
      )
      .then((result) => {
        return result.data;
        
      })
      .catch((error) => {
        //console.log(error.response, "localizarCryptoPorNome");
        if (error.response.status === 401) {
          setAuthenticated(false);
        }
      });
  };

  const sacarBlockChain = async () => {
    //console.log("entrou sacarBlockChain");

    if (coin === "") {
      toast.warning("Selecione a Criptomoeda", {
        position: 'top-right',
      });
      return;
    }
    const asset = listCrypto.walletCryptosPessoa.filter(
      (x) => x.cdCrypto === coin
    );
    const crypto = await localizarCryptoPorNome(asset[0].eliasCrypto);

    //console.log(crypto, "resultado asset");

    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };

    //console.log(textQuantia.length, 'textQuantia.length')
    if (textQuantia.length === 0 || textQuantia === 0) {
      //console.log("quantia deve ser superio a 0");
      toast.warning("quantia deve ser superio a 0", {
        position: 'top-right',
      });
      return;
    }

    if(coinFee === ""){
      toast.warning("Insira a Taxa", {
        position: 'top-right',
      });
      return;
    } 

    if(rede === ""){
      toast.warning("Insira a Rede", {
        position: 'top-right',
      });
      return;
    } 


    if (texEndereco.length === 0) {
      //console.log("Inserir endereco para transação");
      toast.warning("Inserir endereco para transação", {
        position: 'top-right',
      });
      return;
    } else {
      //console.log('sacarBlockChain');

      const body = {
        cryptoUserID: sessionStorage.getItem("uid"),
        nrTipoSaque: localizarTipoSaque("BlockChain"),
        cdCrypto: String(crypto.conteudo.cdCrypto),
        qntCoin: textQuantia,
        rede: textRede,
        hashRemetente: meuEndereco.hashWallet,
        hashDestinatario: texEndereco,
        //cdCryptoTransactionFee:
      };  

      //console.log(body, 'body sacarBlockChain')
      setTransferir("Transferindo")
      setNomeButton("Transferindo ...");
      setColorDynamicButton("grey");
      //setLoadingMsg(true);
      setLoading(true)

      
      partnerAPI
        
        .post("/Partner/Withdraw", body, config)
        .then((result) => {
          //esconder todos os textfield(esconder tudo)
          setLoading(true)
          if (result.data.retorno === "Ordem de Saque para BlockChain Emitida") {
            // console.log('if(result.data.retorno');
            // console.log(result.data, 'retorno sacarBlockChain')
            //setTransferenciaMsg(true);
            //mostrar check de processamento emitido com sucesso(imagem)
           
              setLoading(false);
              setImgConcluido(true);
             
            
            setTimeout(() => {
              //esconder check de processamento
              //setTransferenciaMsg(false);
              setRede(0);
              setCoin(0);
              setTextQuantia("");
              setTexEndereco("");
              setColorDynamicButton("#D4AA51");
              setNomeButton("Transferir");
              setLoadingMsg(false);
              //mostrar textfield
              setImgConcluido(false);
              
              
             // setImgConcluido(false);
             
            }, 5000);
            
          }     

          
          if (result.data.retorno === "no funds") {
            toast.error("Saldo de Taxa insuficiente, Altere a forma da Taxa", {
              position: 'top-right',
            });

              
            setTimeout(() => {
              setTransferenciaMsg(false);
              setColorDynamicButton("#D4AA51");
              //setNomeButton("Transferir");
              //setLoadingMsg(false);
                
             

              
            }, 5000);
          } 
          // console.log(result.data, '/Ordem/saque')
        })
        .catch((error) => {
          // console.log(error.response, '/Ordem/saque')
          toast.error(error.response.messege, {
            position: 'top-right',
          });

          if (error.response.status === 401) {
            setAuthenticated(false);
          }
        });
    }
  };

  return (
    <div className="row col-sm-12 pb-6 mb-5 h-25 d-flex " style={{ padding: 10, paddingBottom: 140,  }}>
      <div className="row col-sm-6 pb-6 mb-5 h-25  ">
        <Card className="col-sm-12 row h-50 " style={{ marginLeft: 10 }}>
          {loading === true?(
        
            <div style={{ fontSize:20, color: 'green',textAlign: 'center', paddingTop:70,   height:250 , marginLeft:-30}}  className="col-sm-12 " >
              <img src="Assets/img/img-processando.png" style={{width:70, paddingBottom:15, alignItens:'center',marginLeft:-30}}/><br/>  Transferindo....
            </div>        
            ): (   
            <>            
              {imgConcluido === true?(
                <div style={{ fontSize:20, color: 'green',textAlign: 'center', paddingTop:70, height:250 , marginLeft:-30}}  className="col-sm-12 " >
                  <img src="Assets/img/img-concluido.png"  style={{width:50,paddingBottom:15,  alignItens:'center',marginLeft:-30}}/> <br/>
                  Ordem processada com sucesso.
                </div>
              ):(                            
                <CardContent  style={{ padding: 20 }}>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>                

                    <FormControl  variant="standard" className="col-12 ">
                      <InputLabel id="coin-controller-label"  value={coin} style={{padding:0}}>
                        Criptomoeda
                      </InputLabel>

                      <Select
                        labelId="coin-controller-label"
                        id="coin-controller-label"
                        open={openCoin}
                        onClose={handleCloseCoin}
                        onOpen={handleOpenCoin}
                        value={coin}
                        label="Coin"
                        onChange={(e) => handleChangeCoin(e)}
                        onBlur={handleChangeCoin}
                        >
                        {!saqueSelect ? (
                          <div style={{ padding: 5 }}>
                            <CircularProgress color="secondary" />
                          </div>
                        ) : (
                          listCrypto.walletCryptosPessoa.map((crypto, index) => (
                            <MenuItem key={index} value={crypto.cdCrypto} className="  "  >
                            <div className="  d-flex align-items-center ">
                              <Avatar alt="Remy Sharp" className="me-2 " style={{ width: 30, height: 30 }} src={crypto.urlImgCrypto} key={index} value={crypto.cdCrypto} />
      
                              <div style={{ marginLeft: 5 }} className="col-sm-6">
                                {crypto.eliasCrypto}
                              </div>
                            </div>
                          </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <div>
                    {coinSaldo >= 0 ? (
                      coinSaldo > 0 ? (
                        <p>Saldo disponivel:{Intl.NumberFormat('pt-BR').format(coinSaldo)}</p>
                      ) : (<p>Sem saldo: {coinSaldo}</p>)
                    ) : (
                    <></>
                    )}

                    <div>
                      <TextField
                          className="col-sm-12"
                          id="outlined-basic"
                          value={texEndereco}
                          onChange={(e) => setTexEndereco(e.target.value)}
                          label="Endereço Depositante"
                          variant="standard"
                        />

                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div  style={{ marginTop: 5 }}>
                          <TextField
                            className="col-sm-12"
                            id="outlined-basic"
                            value={textQuantia}
                            onChange={(e) => setTextQuantia(e.target.value)}
                            label="Quantia"
                            variant="standard"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <FormControl className="col-sm-12 " variant="standard" style={{marginTop: 5}}>
                          <InputLabel id="rede-controller-label">Rede</InputLabel>
                          <Select
                            labelId="rede-controller-label"
                            id="rede-controller-label"
                            open={openRede}
                            onClose={handleCloseRede}
                            onOpen={handleOpenRede}
                            value={rede}
                            label="Rede"
                            onChange={(e)=> handleChangeRede(e)}
                          >
                          {!redeSelect ? (
                                <div style={{ padding: 5 }}>
                                  <CircularProgress color="secondary" />
                                </div>
                          ) : (
                          listRede.rede.map((rede, index) => (
                            <MenuItem key={index} value={rede.nrRede}>
                              {rede.nmRede}
                            </MenuItem>
                            ))
                          )}
                          </Select>
                        </FormControl>

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <FormControl className="col-sm-12" variant="standard" style={{marginTop: 10}}>
                            <InputLabel id="coin-controller-label" value={coinFee}>
                              Taxa
                            </InputLabel>
                            <Select
                              labelId="coin-controller-label"
                              id="coin-controller-label"
                              open={openCoinFee}
                              onClose={handleCloseCoinFee}
                              onOpen={handleOpenCoinFee}
                              value={coinFee}
                              label="Taxa"
                              onChange={(e) => handleChangeCoinFee(e)}
                            >
                              {!saqueSelect ? (
                                <div style={{ padding: 5 }}>
                                  <CircularProgress color="secondary" />
                                </div>
                              ) : (
                                listCrypto.walletCryptosPessoa.map((crypto, index) => (
                                      <MenuItem key={index} value={crypto.cdCrypto} style={{height:45}}>
                                      <div className="row">

                                        <div className="col-sm-1">
                                            <Avatar alt="Remy Sharp" style={{width: 30, height: 30}} src={crypto.urlImgCrypto} />
                                        </div>
                                        <div className="col-sm-6" style={{paddingTop: 4,paddingLeft: 25}}>
                                          {crypto.eliasCrypto}
                                        </div>
                                      </div>
                                      </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>

                      </div>

                      <div className="col-sm-8">
                            <div style={{ marginTop: 20, paddingBottom: 5 }}>
                              <Button
                                className="col-md-12"
                                variant="outlined"
                                onClick={() => sacarBlockChain()}
                                style={{
                                  backgroundColor: colorDynamicButton,
                                  color: "black",
                                  borderBlockColor: "aliceblue",
                                }}
                              >
                                {nomeButton}

                                {loadingMsg && (
                                  <CircularProgress
                                    size={24}
                                    sx={{
                                      color: green[500],
                                      position: "absolute",
                                      top: "50%",
                                      left: "30%",
                                      marginTop: "-12px",
                                      marginLeft: "-12px",
                                    }}
                                  />
                                )}
                              </Button>
                              {transferenciaMsg && (
                                <p style={{ padding: 10, color: "green" }}>
                                  Transferencia Emitida com Sucesso
                                </p>
                              )}
                            </div>

                      </div>
                    </div>

                  </div>
                </CardContent>
              )}
            </> 
          )}
        </Card>                 
      </div>  
                    
      <div className="row col-sm-6 ms-2 h-50 p-25 " style={{alignItems:'center', justifyContent:'center'}}>
        <p className="display-6 " style={{ paddingTop: 25, paddingBottom: 25  }}>
            Saque BlockChain
        </p>
        <div className="text-left " style={{ padding: 10}}>
          <p>
              Certifique que endereço esteja correto. Caso esteja Incorreto
              Ocorrerá perda de ativos
          </p>
        </div>
        
        <Box >
          <Card className="w-25 ">
            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/historico")}>
                <ListItemButton>                 
                  <ListItemText
                    primary="Histórico"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>
          </Card>
        </Box>
      </div>
      <ToastContainer />  
    </div>
  );
}
