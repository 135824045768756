import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./../pages/login/login";
import CadastroConta from "./../pages/login/Cadastro-Conta/cadastroConta";
import Home from "./../pages/home/home";
import Saque from "./../pages/home/saque/saque";
import Deposito from "./../pages/home/deposito/deposito";
import Ajuda from "./../pages/home/ajuda/ajuda";
import Perfil from "./../pages/home/perfil/perfil";
import VerificacaoConta from "./../pages/home/perfil/conta/verficacaoConta";

import ConfiguracoesToken from "./../pages/configuracoes/configuracoesToken";
import DistribuicaoToken from "./../pages/configuracoes/distribuicao/distribuicaoToken";
import TransacaoToken from "./../pages/configuracoes/transacao/transacaoToken";
import DadosToken from "./../pages/configuracoes/dados/dadosToken";
import GarantiaContrato from "./../pages/configuracoes/garantia/garantiaContrato";
import CadastroDistribuidor from "./../pages/configuracoes/distribuidor/cadastroDistribuidor";
import ContratoInteligente from "./../pages/configuracoes/contratoInteligente/index";

import PrivateRoute from "./privateRoute";
import Swap from "./../pages/home/swap/swap";
import Cripto from "./../pages/home/cripto/cripto";
import AreaPix from "./../pages/home/areaPix/areaPix";
import CashBack from "./../pages/home/cashBack/cashBack";
import AdicionarQueimarToken from "./../pages/configuracoes/adicionar-queimar-token/adicionarQueimarToken";
import Historico from "./../pages/home/historico/historico"
import ControleDeFidelidade from "../pages/configuracoes/controleDeFidelidade/controleDeFidelidade";
import Stacking from "../pages/home/stacking/stacking";
import SaibaMaisStacking from "../pages/home/stacking/saibaMaisStacking/saibaMaisStaking";

export default function MainRoutes({ authenticated }) {
  return (
    <Routes>
      <Route path="*" exact element={<Login />}></Route>
      <Route path="/login" exact element={<Login />}></Route>
      <Route path="/cadastro-conta" exact element={<CadastroConta />}></Route>

      <Route element={<PrivateRoute auth={authenticated} />}>
        <Route path="/home" exact element={<Home />}></Route>
        <Route path="/saque" exact element={<Saque />}></Route>
        <Route path="/deposito" exact element={<Deposito />}></Route>
        <Route path="/ajuda" exact element={<Ajuda />}></Route>
        <Route path="/perfil" exact element={<Perfil />}></Route>
        <Route path="/check" exact element={<VerificacaoConta />}></Route>

        <Route path="/configuracoes" exact element={<ConfiguracoesToken />}></Route>
        <Route path="/distribuicao" exact element={<DistribuicaoToken />}></Route>
        <Route path="/transacao" exact element={<TransacaoToken />}></Route>
        <Route path="/dados" exact element={<DadosToken />}></Route>
        <Route path="/garantia" exact element={<GarantiaContrato />}></Route>
        <Route path="/cadastro-distribuidor" exact element={<CadastroDistribuidor />}></Route>
        <Route path="/contratoInteligente" exact element={<ContratoInteligente />}></Route>
        <Route path="/cripto" exact element={<Cripto />}></Route>
        <Route path="/swap" exact element={<Swap />}></Route>
        <Route path="/area-pix" exact element={<AreaPix />}></Route>
        <Route path="/cashBack" exact element={<CashBack />}></Route>
        <Route path="/adicionarTokens"exact element={<AdicionarQueimarToken />}></Route>
        <Route path="/queimarTokens" exact element={<AdicionarQueimarToken />}></Route>
        <Route path="/historico" exact element={<Historico />}></Route>
        <Route path="/controleDeFidelidade" exact element={<ControleDeFidelidade/>}></Route>
        <Route path="/stacking" exact element={<Stacking />}></Route>
        <Route path="/saibaMaisStacking" exact element={<SaibaMaisStacking/>}></Route>
      </Route>
    </Routes>
  );
}
